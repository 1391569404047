<template>
   <div>
      <template v-if="containsSplitSymbol">
         <div>
            {{ splitListValue.title }}
         </div>
         <div class="subtitle">
            {{ splitListValue.subtitle }}
         </div>
      </template>
      <template v-else>
         {{ listValue }}
      </template>
   </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import {} from "@backend/api/pmToolApi";
import ListUtils from "@utils/ListUtils";

@Component({
   name: "SingleColumnListValue",
   components: { ListUtils },
})
export default class SingleColumnListValue extends Vue {
   @Prop({ required: true, default: undefined })
   value!: string | undefined;

   get containsSplitSymbol(): boolean {
      return !!this.value?.includes(ListUtils.splitSymbol);
   }

   get listValue(): string | undefined {
      return this.value;
   }

   get splitListValue(): { title: string; subtitle: string } {
      let valueParts = this.listValue.split(ListUtils.splitSymbol, 2);
      return {
         title: valueParts[0]?.trim(),
         subtitle: valueParts[1]?.trim(),
      };
   }
}
</script>
