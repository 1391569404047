<template></template>
<script lang="ts">
import { Vue, Component, Watch } from "vue-property-decorator";
import { EntityStatus, EntityType } from "@backend/api/pmToolApi";
import { MenuTabVariant } from "@backend/api/enums/menuTabVariant";
import gridOperationStore from "@backend/store/gridOperationsStore";
import { DataTableHeader, DataOptions } from "vuetify";
import moment from "moment";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import type { QueryOptions, PagingStore } from "@utils/Paging";

export const MIN_PAGE_SIZE = 10;

// @ts-expect-error Vue complains about virtual components, since it is never created it works just fine
@Component({
   name: "OverviewBase",
})
export default abstract class OverviewBase extends ComponentBase implements PagingStore {
   loadingInternal: boolean = false;
   menuVariant: MenuTabVariant = MenuTabVariant.Default;
   entityType: EntityType | undefined | null;
   pagingTotalDocuments: number = 100;
   pagingInfo = [];
   pagingLastPage: number = -1;
   pagingLastPageSize: number = 5;
   anIncreasingNumber = 1;
   public headers: DataTableHeader[];
   selectedHeaders: Array<DataTableHeader> = [];
   dataTableOptions: DataOptions = {
      page: 1,
      itemsPerPage: 20,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      mustSort: false,
      multiSort: false,
   };
   dataTableOptionsWatch: any;
   footerProps = { "items-per-page-options": [MIN_PAGE_SIZE, 20, 50, 100] };

   //------------------- to be overriden --------------------
   protected abstract loadData: Function;
   protected abstract defaultDataTableOptions: Partial<DataOptions> | undefined;

   @Watch("dataTableOptions.itemsPerPage")
   pageSizeChange() {
      gridOperationStore.setPageSize(this.dataTableOptions.itemsPerPage, this.entityType);
   }

   get loading(): boolean {
      return this.loadingInternal;
   }

   set loading(value: boolean) {
      let appInsights = <ApplicationInsights>(<any>Vue).appInsights;
      if (appInsights) {
         try {
            let name = <string>this.$route.name + "/Overview";
            if (!this.loadingInternal && value) {
               appInsights.startTrackPage(name);
            } else if (this.loadingInternal && !value) {
               const url = location.protocol + "//" + location.host + this.$route.fullPath;
               appInsights.stopTrackPage(name, url);
               appInsights.flush();
            }
         } catch (e) {
            console.warn("Failed to create/send telemetry:", e);
         }
      }

      this.loadingInternal = value;
   }

   getEntityStatusRowClass(item) {
      switch (item.entityStatus) {
         case EntityStatus.Deleted:
            return "table-row-entity-status-inactive";
         default:
            return "table-row-entity-status-create-or-draft";
      }
   }

   customSearch(items, search, filter) {
      var searchArray = search.split(" ");
      var isGoodForSearchString = false;
      for (var i = 0; i < searchArray.length; i++) {
         if (isGoodForSearchString == false) {
            isGoodForSearchString = Object.values(filter).some(
               // Context unclear as `filter` is untyped
               // eslint-disable-next-line @typescript-eslint/no-base-to-string
               (v) => v && v.toString().toLowerCase().includes(searchArray[i].toString().toLowerCase())
            );
         }
      }
      return isGoodForSearchString;
   }

   changeTabStyle() {
      if (this.menuVariant == MenuTabVariant.Default) this.menuVariant = MenuTabVariant.Icons;
      else if (this.menuVariant == MenuTabVariant.Icons) this.menuVariant = MenuTabVariant.Names;
      else if (this.menuVariant == MenuTabVariant.Names) this.menuVariant = MenuTabVariant.Default;
   }

   OnBeforePagedQuery(queryOptions: QueryOptions) {
      var continuationToken: string | undefined = undefined;

      if (
         this.pagingLastPageSize != this.dataTableOptions.itemsPerPage ||
         this.pagingLastPage === this.dataTableOptions.page
      ) {
         this.pagingInfo = [];
      } else {
         if (this.pagingLastPage > -1) {
            if (this.dataTableOptions.page < this.pagingLastPage) {
               this.pagingLastPage = this.dataTableOptions.page - 1;
            }

            let pageInfo = this.pagingInfo.filter((x) => x.page == this.pagingLastPage);
            if (pageInfo && pageInfo.length > 0) {
               continuationToken = pageInfo[0].continuationToken;
            }
         }
      }

      queryOptions.continuationToken = continuationToken;
      queryOptions.pageSize = this.dataTableOptions.itemsPerPage;
      queryOptions.sortBy = this.dataTableOptions.sortBy;
      queryOptions.sortDesc = this.dataTableOptions.sortDesc;
   }

   OnAfterPagedQuery(continuationToken: string, totalDocuments: number) {
      this.pagingTotalDocuments = totalDocuments;
      let pageInfo = this.pagingInfo.filter((x) => x.page == this.dataTableOptions.page);
      this.pagingLastPage = this.dataTableOptions.page;
      this.pagingLastPageSize = this.dataTableOptions.itemsPerPage;
      if (pageInfo && pageInfo.length > 0) {
         pageInfo[0].continuationToken = continuationToken;
      } else {
         this.pagingInfo.push({
            page: this.dataTableOptions.page,
            continuationToken: continuationToken,
         });
      }
   }

   updateHeaderOrder(evt) {
      if (this.entityType) {
         gridOperationStore.updateHeaderOrder(evt, this.selectedHeaders, this.entityType);
         this.anIncreasingNumber++;
      }
   }

   orderColumns() {
      if (this.entityType) {
         const savedConf = gridOperationStore.getSelectedColumnsOrDefaultForOverview(this.entityType);
         let result: DataTableHeader[] = [];
         savedConf.forEach((x) => {
            let match = this.headers.find((y) => y.value == x);
            if (match) {
               result.push(match);
            }
         });

         this.selectedHeaders = result;
      }
   }

   setColumns(selected: string[]) {
      this.selectedHeaders = this.headers.filter((x) => selected.some((y) => y == x.value));
      this.orderColumns();
      this.loadData();
      this.anIncreasingNumber++;
   }

   getFormatedTime(value: string) {
      let time = moment.duration(value);
      if (time && time.asMilliseconds() >= 1) {
         return time.format();
      }
      return "";
   }

   //-------- Permissions --------
   canCreate: boolean | null = null;

   get isCreateButtonShown() {
      return this.canCreate && !this.loading;
   }

   onDataTableOptionsChanged(n, o) {
      if (!n || !o || JSON.stringify(n) !== JSON.stringify(o)) {
         this.loadData();
      }
   }

   mounted() {
      this.dataTableOptions = {
         ...this.dataTableOptions,
         ...this.defaultDataTableOptions,
         itemsPerPage: gridOperationStore.getPageSize(this.entityType),
      };

      this.dataTableOptionsWatch = this.$watch("dataTableOptions", this.onDataTableOptionsChanged, { deep: true });
   }

   unmounted() {
      if (this.dataTableOptionsWatch) {
         this.dataTableOptionsWatch();
      }
   }
}
</script>
