import {} from "@backend/api/pmToolApi";

class ListUtils {
   // ------------ Single list value formatting ------------
   /**
    * A symbol used to visual formatting List values.
    * List value is displayed as Title and Subtitle if text contains 'splitSymbol' (List value example: "title | subtitle").
    * Otherwise List value is displayed as standart one-line text.
    */
   public readonly splitSymbol = "|";
   /**
    *  Format pattern used for validation of List values that contains a 'splitSymbol'.
    */
   public readonly splitSymbolValuePattern = new RegExp(/^\s*([^|\s]+[^|]*)\|\s*([^|\s]+[^|]*)\s*$/);
}

export default new ListUtils();
