//authorization
import TreeView from "@components/TreeView/tree-view.vue";
import Administration from "@components/Administration/administration.vue";
import { RouteNavValue } from "@models/RouteNavValue";
import { Location } from "vue-router";

const PBBDetail = () => import(/* webpackChunkName: "PBBDetail" */ "@components/PBB/pbb-detail.vue");
const PbbSimulation = () =>
   import(/* webpackChunkName: "PbbSimulation" */ "@components/PBB/Simulation/pbb-simulation.vue");
const AccountDetail = () =>
   import(/* webpackChunkName: "AccountDetail" */ "@components/AccountManagement/account-detail.vue");
const PBBOverView = () => import(/* webpackChunkName: "PBBOverView" */ "@components/PBB/Overview/pbb-overview.vue");
const PBBQueryOverview = () =>
   import(/* webpackChunkName: "PBBQueryOverview" */ "@components/PBB/Queries/queries-overview.vue");
const UserList = () =>
   import(/* webpackChunkName: "UserList" */ "@components/UserAdministration/Users/users-overview.vue");
const Userdetial = () =>
   import(/* webpackChunkName: "Userdetial" */ "@components/UserAdministration/Users/user-detail.vue");
const GroupList = () =>
   import(/* webpackChunkName: "GroupList" */ "@components/UserAdministration/Groups/groups-overview.vue");
const RoleList = () =>
   import(/* webpackChunkName: "RoleList" */ "@components/UserAdministration/Roles/roles-overview.vue");
const RoleDetail = () =>
   import(/* webpackChunkName: "RoleDetail" */ "@components/UserAdministration/Roles/roles-detail.vue");
const CapabilityList = () =>
   import(/* webpackChunkName: "CapabilityList" */ "@components/Capabilities/capabilities-overview.vue");
const ListOverview = () => import(/* webpackChunkName: "ListOverview" */ "@components/Lists/list-overview.vue");
const QualificationOverview = () =>
   import(/* webpackChunkName: "QualificationOverview" */ "@components/Qualifications/qualification-overview.vue");
const QualificationDetail = () =>
   import(/* webpackChunkName: "QualificationDetail" */ "@components/Qualifications/qualification-detail.vue");
const OrgChart = () =>
   import(/* webpackChunkName: "OrgChart" */ "@components/UserAdministration/OrganiGram/org-chart.vue");
const OrgChartDetail = () =>
   import(/* webpackChunkName: "OrgChartDetail" */ "@components/UserAdministration/OrganiGram/org-chart-detail.vue");
const GroupDetail = () =>
   import(/* webpackChunkName: "GroupDetail" */ "@components/UserAdministration/Groups/groups-detail.vue");
const CapabilityDetail = () =>
   import(/* webpackChunkName: "CapabilityDetail" */ "@components/Capabilities/capability-detail.vue");
const ListDetail = () => import(/* webpackChunkName: "CapabilityDetail" */ "@components/Lists/list-detail.vue");
const DomainDataModelList = () =>
   import(
      /* webpackChunkName: "DomainDataModelList" */ "@components/PBB/DomainDataModels/Overview/domain-data-models-overview.vue"
   );
const DomainDataModelDetail = () =>
   import(
      /* webpackChunkName: "DomainDataModelDetail" */ "@components/PBB/DomainDataModels/domain-data-model-detail.vue"
   );
const DomainDataModelInstanceList = () =>
   import(
      /* webpackChunkName: "DomainDataModelInstanceList" */ "@components/DomainDataModelInstance/domain-data-model-instance-overview.vue"
   );
const DomainDataModelInstanceDetail = () =>
   import(
      /* webpackChunkName: "DomainDataModelInstanceDetail" */ "@components/DomainDataModelInstance/domain-data-model-instance-detail.vue"
   );
const DomainList = () => import(/* webpackChunkName: "DomainList" */ "@components/Domains/domains-overview.vue");
const ProjectDraftList = () =>
   import(/* webpackChunkName: "ProjectDraftList" */ "@components/Project/Overview/project-definition-overview.vue");
const ProjectList = () =>
   import(/* webpackChunkName: "ProjectList" */ "@components/Project/Overview/project-overview.vue");
const ProjectDetail = () => import(/* webpackChunkName: "ProjectDetail" */ "@components/Project/project-detail.vue");
const ProjectDefinitionDetail = () =>
   import(
      /* webpackChunkName: "ProjectDefinitionDetail" */ "@components/Project/ProjectDefinition/project-definition-detail.vue"
   );
const ReportList = () => import(/* webpackChunkName: "ReportList" */ "@components/Report/Overview/report-overview.vue");
const ReportGridDetail = () =>
   import(/* webpackChunkName: "ReportGridDetail" */ "@components/Report/Grid/report-grid.vue");
const ReportKanbanDetail = () =>
   import(/* webpackChunkName: "ReportKanbanDetail" */ "@components/Report/Kanban/report-kanban-detail.vue");
const TagList = () => import(/* webpackChunkName: "TagList" */ "@components/TagAdministration/tag-overview.vue");
const TagDetail = () => import(/* webpackChunkName: "TagDetail" */ "@components/TagAdministration/tag-detail.vue");
const ContentBrickList = () =>
   import(/* webpackChunkName: "ContentBrickList" */ "@components/ContentBricks/content-brick-overview.vue");
//const ContentBrickDetail = () => import(/* webpackChunkName: "ContentBrickDetail" */ "@components/ContentBricks/content-brick-detail.vue");
import ContentBrickDetail from "@components/ContentBricks/content-brick-detail.vue"; // cannot chunk ContentBrickDetail - locally imported boostrap on detail overwrites vuetify styles
import { ContentBrickType } from "@backend/api/pmToolApi";
const SubContentBrickList = () =>
   import(/* webpackChunkName: "SubContentBrickList" */ "@components/ContentBricks/sub-content-brick-overview.vue");
//const SubContentBrickDetail = () => import(/* webpackChunkName: "SubContentBrickDetail" */ "@components/ContentBricks/sub-content-brick-detail.vue");
import SubContentBrickDetail from "@components/ContentBricks/sub-content-brick-detail.vue"; // cannot chunk SubContentBrickDetail - locally imported boostrap on detail overwrites vuetify styles
const UnitsList = () => import(/* webpackChunkName: "UnitsList" */ "@components/Units/units-overview.vue");
const UnitDetail = () => import(/* webpackChunkName: "UnitDetail" */ "@components/Units/unit-detail.vue");
const UnitsTypeList = () =>
   import(/* webpackChunkName: "UnitsTypeList" */ "@components/UnitsType/units-type-overview.vue");
const UnitTypeDetail = () =>
   import(/* webpackChunkName: "UnitTypeDetail" */ "@components/UnitsType/unit-type-detail.vue");
const TaskList = () => import(/* webpackChunkName: "TaskList" */ "@components/Tasklist/task-list-overview.vue");
const TaskDetail = () => import(/* webpackChunkName: "TaskDetail" */ "@components/Tasklist/task-detail.vue");
const TechnicalMeasureList = () =>
   import(
      /* webpackChunkName: "TechnicalMeasureList" */ "@components/TechnicalMeasures/technical-measure-overview.vue"
   );
const TechnicalMeasureDetail = () =>
   import(
      /* webpackChunkName: "TechnicalMeasureDetail" */ "@components/TechnicalMeasures/technical-measure-detail.vue"
   );
const DataTableList = () =>
   import(/* webpackChunkName: "DataTableList" */ "@components/DataTables/data-table-overview.vue");
const DataTableDetail = () =>
   import(/* webpackChunkName: "DataTableDetail" */ "@components/DataTables/data-table-detail.vue");
const PublicHolidays = () =>
   import(/* webpackChunkName: "PublicHolidays" */ "@components/PublicHolidays/public-holidays.vue");
const PublicHolidayDetail = () =>
   import(/* webpackChunkName: "PublicHolidayDetail" */ "@components/PublicHolidays/public-holiday-detail.vue");
const Translations = () =>
   import(/* webpackChunkName: "Translations" */ "@components/Translations/translations-overview.vue");
const UsageTypes = () => import(/* webpackChunkName: "UsageTypes" */ "@components/UsageTypes/usage-types-overview.vue");
const UsageTypeDetail = () =>
   import(/* webpackChunkName: "UsageTypeDetail" */ "@components/UsageTypes/usage-type-detail.vue");
const AutomaticImportOverview = () =>
   import(
      /* webpackChunkName: "AutomaticImportOverview" */ "@components/AutomaticImports/automatic-import-overview.vue"
   );
const AutomaticImportDetail = () =>
   import(/* webpackChunkName: "AutomaticImportDetail" */ "@components/AutomaticImports/automatic-import-detail.vue");
const LibraryList = () => import(/* webpackChunkName: "LibraryList" */ "@components/Library/library-overview.vue");
const LibraryDetail = () => import(/* webpackChunkName: "LibraryDetail" */ "@components/Library/library-detail.vue");
const EnactmentPriorityLabelList = () =>
   import(
      /* webpackChunkName: "EnactmentPriorityLabelList" */ "@components/EnactmentPriorityLabel/enactment-priority-label-overview.vue"
   );
const EnactmentPriorityLabelDetail = () =>
   import(
      /* webpackChunkName: "EnactmentPriorityLabelDetail" */ "@components/EnactmentPriorityLabel/enactment-priority-label-detail.vue"
   );
const NotificationHistory = () =>
   import(/* webpackChunkName: "NotificationHistory" */ "@components/Notifications/notification-history.vue");
const DomainDetail = () => import(/* webpackChunkName: "DomainDetail" */ "@components/Domains/domains-detail.vue");
const RegularExpressionList = () =>
   import(
      /* webpackChunkName: "RegularExpressionsList" */ "@components/RegularExpressions/regular-expression-overview.vue"
   );
const RegularExpressionDetail = () =>
   import(
      /* webpackChunkName: "RegularExpressionsDetail" */ "@components/RegularExpressions/regular-expression-detail.vue"
   );
const MaintenancePage = () => import(/* webpackChunkName: "MaintenancePage" */ "@components/Home/maintenance-page.vue");

const DynamicTitlePlaceholder = "Loading...";

const routesDefs = [
   {
      path: "/",
      component: TaskList,
      meta: {
         title: "Tasks Overview",
         requiresAuth: true,
      },
      name: "home",
      navValue: RouteNavValue.Tasks,
   },
   {
      path: "/maintenance",
      component: MaintenancePage,
      meta: {
         title: "System maintenance",
         requiresAuth: true,
      },
      name: "maintenance",
      navValue: RouteNavValue.Dashbord,
   },
   {
      path: "*",
      component: TaskList,
      meta: {
         title: "Page not found",
      },
      name: "all",
      navValue: RouteNavValue.Tasks,
   },
   {
      path: "/treeview" /*to-do remove unused component?*/,
      component: TreeView,
      meta: {
         title: "PMP - TreeView",
      },
      name: "tree-view",
   },
   {
      path: "/pbb/detail/:id",
      component: PBBDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - PBB Detail`,
         requiresAuth: true,
      },
      props: (route) => ({ query: route.query.restoreTab }),
      name: "pbb-detail",
      navValue: RouteNavValue.PBB,
   },
   {
      path: "/pbb/new",
      component: PBBDetail,
      meta: {
         title: `Create PBB`,
         requiresAuth: true,
      },
      name: "pbb-detail-new",
      navValue: RouteNavValue.PBB,
   },
   {
      path: "/pbb/detail/:id/simulation",
      component: PbbSimulation,
      meta: {
         title: `${DynamicTitlePlaceholder} - PBB Simulation`,
         requiresAuth: true,
      },
      name: "pbb-simulation",
      navValue: RouteNavValue.PBB,
   },
   {
      path: "/account" /*to-do remove unused component?*/,
      component: AccountDetail,
      meta: {
         title: "PMP - Account Detail",
         requiresAuth: true,
      },
      name: "account-detail",
   },
   {
      path: "/pbb/overview",
      component: PBBOverView,
      meta: {
         title: "PBB Overview",
         requiresAuth: true,
      },
      name: "pbb-overview",
      navValue: RouteNavValue.PBB,
   },
   {
      path: "/pbb/queries/overview",
      component: PBBQueryOverview,
      meta: {
         title: "PBB queries Overview",
         requiresAuth: true,
      },
      name: "pbb-queries-overview",
      navValue: RouteNavValue.PbbQueries,
   },
   {
      path: "/user/overview",
      component: UserList,
      meta: {
         title: "Users",
         requiresAuth: true,
      },
      name: "user-list",
      navValue: RouteNavValue.Users,
   },
   {
      path: "/user/detail/:id",
      component: Userdetial,
      meta: {
         title: `${DynamicTitlePlaceholder} - User Detail`,
         requiresAuth: true,
      },
      name: "user-detail",
      navValue: RouteNavValue.Users,
   },
   {
      path: "/user/groups",
      component: GroupList,
      meta: {
         title: "User Groups",
         requiresAuth: true,
      },
      name: "group-list",
      navValue: RouteNavValue.Users,
   },
   {
      path: "/user/roles" /*to-do remove unused component?*/,
      component: RoleList,
      meta: {
         title: "Roles",
         requiresAuth: true,
      },
      name: "role-list",
      navValue: RouteNavValue.Roles,
   },
   {
      path: "/user/roles/detail/:permissionType/:permission",
      component: RoleDetail,
      meta: {
         title: "PMP - Role Detail",
         requiresAuth: true,
      },
      name: "role-detail-systemRole",
      navValue: RouteNavValue.Roles,
   },
   {
      path: "/user/roles/detail/:permissionType/:permission/:domain",
      component: RoleDetail,
      meta: {
         title: "PMP - Role Detail",
         requiresAuth: true,
      },
      name: "role-detail-domainRole",
      navValue: RouteNavValue.Roles,
   },
   {
      path: "/user/orgChart",
      component: OrgChart,
      meta: {
         title: "OrganiGram",
         requiresAuth: true,
      },
      name: "org-chart",
      navValue: RouteNavValue.Organigram,
   },
   {
      path: "/user/orgChart/detail/:id",
      component: OrgChartDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - OrganiGram Detail`,
         requiresAuth: true,
      },
      name: "org-chart-detail",
      navValue: RouteNavValue.Organigram,
   },
   {
      path: "/user/orgChart/detail/:id/:childId",
      component: OrgChartDetail,
      meta: {
         title: "Create OrganiGram",
         requiresAuth: true,
      },
      name: "org-chart-detail-child",
      navValue: RouteNavValue.Organigram,
   },
   {
      path: "/user/orgChart/detail/:id/:childId/:sibling",
      component: OrgChartDetail,
      meta: {
         title: "Create OrganiGram sibling",
         requiresAuth: true,
      },
      name: "org-chart-detail-sibling",
      navValue: RouteNavValue.Organigram,
   },
   {
      path: "/groups/detail/:id",
      component: GroupDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Group Detail`,
         requiresAuth: true,
      },
      name: "group-detail",
      navValue: RouteNavValue.Groups,
   },
   {
      path: "/groups/new",
      component: GroupDetail,
      meta: {
         title: "Create User Group",
         requiresAuth: true,
      },
      name: "group-detail-new",
      navValue: RouteNavValue.Groups,
   },
   {
      path: "/qualifications/overview",
      component: QualificationOverview,
      meta: {
         title: "Qualifications",
         requiresAuth: true,
      },
      name: "qualification-overview",
      navValue: RouteNavValue.Qualifications,
   },
   {
      path: "/qualifications/detail/:id",
      component: QualificationDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Qualification`,
         requiresAuth: true,
      },
      name: "qualification-detail",
      navValue: RouteNavValue.Qualifications,
   },
   {
      path: "/qualifications/new",
      component: QualificationDetail,
      meta: {
         title: "Create Qualification",
         requiresAuth: true,
      },
      name: "qualification-detail-new",
      navValue: RouteNavValue.Qualifications,
   },
   {
      path: "/capabilities/overview",
      component: CapabilityList,
      meta: {
         title: "Capabilities",
         requiresAuth: true,
      },
      name: "capability-list",
      navValue: RouteNavValue.Capabilities,
   },
   {
      path: "/capabilities/detail/:id",
      component: CapabilityDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Capability`,
         requiresAuth: true,
      },
      name: "capability-detail",
      navValue: RouteNavValue.Capabilities,
   },
   {
      path: "/capabilities/new",
      component: CapabilityDetail,
      meta: {
         title: "Create Capability",
         requiresAuth: true,
      },
      name: "capability-detail-new",
      navValue: RouteNavValue.Capabilities,
   },
   {
      path: "/list/overview",
      component: ListOverview,
      meta: {
         title: "Lists",
         requiresAuth: true,
      },
      name: "list-overview",
      navValue: RouteNavValue.Lists,
   },
   {
      path: "/list/detail/:id",
      component: ListDetail,
      meta: {
         title: "Loading...  - List",
         requiresAuth: true,
      },
      name: "list-detail",
      navValue: RouteNavValue.Lists,
   },
   {
      path: "/list/new",
      component: ListDetail,
      meta: {
         title: "Create List",
         requiresAuth: true,
      },
      name: "list-detail-new",
      navValue: RouteNavValue.Lists,
   },
   {
      path: "/domainDataModels/overview",
      component: DomainDataModelList,
      meta: {
         title: "Domain Data Models",
         requiresAuth: true,
      },
      name: "domain-data-model-list",
      navValue: RouteNavValue.DomainDataModels,
   },
   {
      path: "/domainDataModels/detail/:id",
      component: DomainDataModelDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Domain Data Model Detail`,
         requiresAuth: true,
      },
      name: "domain-data-model-detail",
      navValue: RouteNavValue.DomainDataModels,
   },
   {
      path: "/domainDataModels/detail/:id/node/:nodeId",
      component: DomainDataModelDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Domain Data Model Detail`,
         requiresAuth: true,
      },
      name: "domain-data-model-detail-node",
      navValue: RouteNavValue.DomainDataModels,
   },
   {
      path: "/domainDataModels/new",
      component: DomainDataModelDetail,
      meta: {
         title: "Create Domain Data Model",
         requiresAuth: true,
      },
      name: "domain-data-model-detail-new",
      navValue: RouteNavValue.DomainDataModels,
   },
   {
      path: "/domainDataModelInstances/overview",
      component: DomainDataModelInstanceList,
      meta: {
         title: "Domain Data Model Instances",
         requiresAuth: true,
      },
      name: "domain-data-model-instance-list",
      navValue: RouteNavValue.DomainDataModelInstances,
   },
   {
      /* because of the history hacks in DMI detail for Node link, need to stay at the same route name when following a node link 
         otherwise Vue router breaks. Using optional param for this case
         */
      path: "/domainDataModelInstances/detail/:id/:nodeId?",
      component: DomainDataModelInstanceDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Domain Data Model Instance Detail`,
         requiresAuth: true,
      },
      name: "domain-data-model-instance-detail",
      navValue: RouteNavValue.DomainDataModelInstances,
   },
   {
      path: "/domainDataModelInstances/detail/:id/node/:nodeId",
      component: DomainDataModelInstanceDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Domain Data Model Instance Detail`,
         requiresAuth: true,
      },
      name: "domain-data-model-instance-node-detail",
      navValue: RouteNavValue.DomainDataModelInstances,
   },
   {
      path: "/domainDataModelInstances/new",
      component: DomainDataModelInstanceDetail,
      meta: {
         title: "Create Domain Data Model Instance",
         requiresAuth: true,
      },
      name: "domain-data-model-instance-detail-new",
      navValue: RouteNavValue.DomainDataModelInstances,
   },
   {
      path: "/domains",
      component: DomainList,
      meta: {
         title: "Domains",
         requiresAuth: true,
      },
      name: "domain-list",
      navValue: RouteNavValue.Domains,
   },
   {
      path: "/project/overview",
      component: ProjectList,
      meta: {
         title: "Projects",
         requiresAuth: true,
      },
      name: "project-list",
      navValue: RouteNavValue.Projects,
   },
   {
      path: "/project/draft-overview",
      component: ProjectDraftList,
      meta: {
         title: "Draft Projects",
         requiresAuth: true,
      },
      name: "project-list-draft",
      navValue: RouteNavValue.ProjectsDraft,
   },
   {
      path: "/project/detail/:id",
      component: ProjectDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Project Detail`,
         requiresAuth: true,
      },
      name: "project-detail",
      navValue: RouteNavValue.Projects,
   },
   {
      path: "/project/detail/:id/dmiNode/:dmiNodeId",
      component: ProjectDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Project Detail`,
         requiresAuth: true,
      },
      name: "project-dmi-node-detail",
      navValue: RouteNavValue.Projects,
   },
   {
      path: "/project/detail/code/:code",
      component: ProjectDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Project Detail`,
         requiresAuth: true,
      },
      name: "project-detail-code",
      navValue: RouteNavValue.Projects,
   },
   {
      path: "/projectDraft/detail/:id",
      component: ProjectDefinitionDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Project Draft Detail`,
         requiresAuth: true,
      },
      name: "project-definition-detail",
      navValue: RouteNavValue.ProjectsDraft,
   },
   {
      path: "/projectDraft/new",
      component: ProjectDefinitionDetail,
      meta: {
         title: "Create Project Draft",
         requiresAuth: true,
      },
      name: "project-definition-detail-new",
      navValue: RouteNavValue.ProjectsDraft,
   },
   {
      path: "/report/overview/:tab",
      component: ReportList,
      meta: {
         title: "Reports",
         requiresAuth: true,
      },
      name: "report-list",
      navValue: RouteNavValue.Reports,
   },
   {
      path: "/report/grid/detail/:id",
      component: ReportGridDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Grid Report`,
         requiresAuth: true,
      },
      name: "report-grid-detail",
      navValue: RouteNavValue.Reports,
   },
   {
      path: "/report/grid/detail/new",
      component: ReportGridDetail,
      meta: {
         title: `Create Grid Report`,
         requiresAuth: true,
      },
      name: "report-grid-detail-new",
      navValue: RouteNavValue.Reports,
   },
   {
      path: "/report/kanban/detail/:id",
      component: ReportKanbanDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Kanban Report`,
         requiresAuth: true,
      },
      name: "report-kanban-detail",
      navValue: RouteNavValue.Reports,
   },
   {
      path: "/report/kanban/detail/new",
      component: ReportKanbanDetail,
      meta: {
         title: `Create Kanban Report`,
         requiresAuth: true,
      },
      name: "report-kanban-detail-new",
      navValue: RouteNavValue.Reports,
   },
   {
      path: "/tag/overview",
      component: TagList,
      meta: {
         title: "Tags",
         requiresAuth: true,
      },
      name: "tag-list",
      navValue: RouteNavValue.TagsAndSearchIdentifiers,
   },
   {
      path: "/tag/detail/:id",
      component: TagDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Tag detail`,
         requiresAuth: true,
      },
      name: "tag-detail",
      navValue: RouteNavValue.TagsAndSearchIdentifiers,
   },
   {
      path: "/tag/new",
      component: TagDetail,
      meta: {
         title: "Create Tag",
         requiresAuth: true,
      },
      name: "tag-detail-new",
      navValue: RouteNavValue.TagsAndSearchIdentifiers,
   },
   {
      path: "/technicalMeasure/overview",
      component: TechnicalMeasureList,
      meta: {
         title: "Technical Measures",
         requiresAuth: true,
      },
      name: "technical-measure-list",
      navValue: RouteNavValue.TechnicalMeasures,
   },
   {
      path: "/technicalMeasure/detail/:id",
      component: TechnicalMeasureDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Technical Measure detail`,
         requiresAuth: true,
      },
      name: "technical-measure-detail",
      navValue: RouteNavValue.TechnicalMeasures,
   },
   {
      path: "/technicalMeasure/new",
      component: TechnicalMeasureDetail,
      meta: {
         title: "Create Technical Measure",
         requiresAuth: true,
      },
      name: "technical-measure-detail-new",
      navValue: RouteNavValue.TechnicalMeasures,
   },
   {
      path: "/dataTables/overview",
      component: DataTableList,
      meta: {
         title: "Data Table",
         requiresAuth: true,
      },
      name: "data-table-list",
      navValue: RouteNavValue.DataTables,
   },
   {
      path: "/dataTables/detail/:id",
      component: DataTableDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Data Table detail`,
         requiresAuth: true,
      },
      name: "data-table-detail",
      navValue: RouteNavValue.DataTables,
   },
   {
      path: "/dataTables/new",
      component: DataTableDetail,
      meta: {
         title: "Create Data Table",
         requiresAuth: true,
      },
      name: "data-table-detail-new",
      navValue: RouteNavValue.DataTables,
   },
   {
      path: "/contentBricks/overview",
      component: ContentBrickList,
      meta: {
         title: "Content Bricks",
         requiresAuth: true,
      },
      name: "content-brick-list",
      props: { cbType: ContentBrickType.ContentBrick },
      navValue: RouteNavValue.ContentBricks,
   },
   {
      path: "/contentBricks/new",
      component: ContentBrickDetail,
      meta: {
         title: "Create Content Brick",
         requiresAuth: true,
      },
      name: "content-brick-detail-new",
      props: { cbType: ContentBrickType.ContentBrick },
      navValue: RouteNavValue.ContentBricks,
   },
   {
      path: "/contentBricks/detail/:id",
      component: ContentBrickDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Content Brick Detail`,
         requiresAuth: true,
      },
      name: "content-brick-detail",
      props: { cbType: ContentBrickType.ContentBrick },
      navValue: RouteNavValue.ContentBricks,
   },
   {
      path: "/designGuidelines/overview",
      component: ContentBrickList,
      meta: {
         title: "Design Guidelines",
         requiresAuth: true,
      },
      props: { cbType: ContentBrickType.DesignGuideline },
      name: "design-guideline-list",
      navValue: RouteNavValue.DesignGuideline,
   },
   {
      path: "/designGuidelines/new",
      component: ContentBrickDetail,
      meta: {
         title: "Create Design Guideline",
         requiresAuth: true,
      },
      props: { cbType: ContentBrickType.DesignGuideline },
      name: "design-guideline-detail-new",
      navValue: RouteNavValue.DesignGuideline,
   },
   {
      path: "/designGuidelines/detail/:id",
      component: ContentBrickDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Design Guideline Detail`,
         requiresAuth: true,
      },
      name: "design-guideline-detail",
      props: { cbType: ContentBrickType.DesignGuideline },
      navValue: RouteNavValue.DesignGuideline,
   },
   {
      path: "/practicalTests/overview",
      component: ContentBrickList,
      meta: {
         title: "Practical Tests",
         requiresAuth: true,
      },
      props: { cbType: ContentBrickType.PracticalTest },
      name: "practical-test-list",
      navValue: RouteNavValue.PracticalTests,
   },
   {
      path: "/practicalTests/new",
      component: ContentBrickDetail,
      meta: {
         title: "Create Practical Test",
         requiresAuth: true,
      },
      props: { cbType: ContentBrickType.PracticalTest },
      name: "practical-test-detail-new",
      navValue: RouteNavValue.PracticalTests,
   },
   {
      path: "/practicalTests/detail/:id",
      component: ContentBrickDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Practical Test Detail`,
         requiresAuth: true,
      },
      name: "practical-test-detail",
      props: { cbType: ContentBrickType.PracticalTest },
      navValue: RouteNavValue.PracticalTests,
   },
   {
      path: "/generalTests/overview",
      component: ContentBrickList,
      meta: {
         title: "General Tests",
         requiresAuth: true,
      },
      props: { cbType: ContentBrickType.GeneralTest },
      name: "general-test-list",
      navValue: RouteNavValue.GeneralTests,
   },
   {
      path: "/generalTests/new",
      component: ContentBrickDetail,
      meta: {
         title: "Create General Test",
         requiresAuth: true,
      },
      props: { cbType: ContentBrickType.GeneralTest },
      name: "general-test-detail-new",
      navValue: RouteNavValue.GeneralTests,
   },
   {
      path: "/generalTests/detail/:id",
      component: ContentBrickDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - General Test Detail`,
         requiresAuth: true,
      },
      name: "general-test-detail",
      props: { cbType: ContentBrickType.GeneralTest },
      navValue: RouteNavValue.GeneralTests,
   },
   {
      path: "/normsAndStandards/overview",
      component: ContentBrickList,
      meta: {
         title: "Norms And Standards",
         requiresAuth: true,
      },
      props: { cbType: ContentBrickType.NormsAndStandards },
      name: "norms-and-standards-list",
      navValue: RouteNavValue.NormsAndStandards,
   },
   {
      path: "/normsAndStandards/new",
      component: ContentBrickDetail,
      meta: {
         title: "Create Norms And Standards",
         requiresAuth: true,
      },
      props: { cbType: ContentBrickType.NormsAndStandards },
      name: "norms-and-standards-detail-new",
      navValue: RouteNavValue.NormsAndStandards,
   },
   {
      path: "/normsAndStandards/detail/:id",
      component: ContentBrickDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Norms And Standards Detail`,
         requiresAuth: true,
      },
      name: "norms-and-standards-detail",
      props: { cbType: ContentBrickType.NormsAndStandards },
      navValue: RouteNavValue.NormsAndStandards,
   },
   {
      path: "/materialCompliance/overview",
      component: ContentBrickList,
      meta: {
         title: "Material Compliance",
         requiresAuth: true,
      },
      props: { cbType: ContentBrickType.MaterialCompliance },
      name: "material-compliance-list",
      navValue: RouteNavValue.MaterialCompliances,
   },
   {
      path: "/materialCompliance/new",
      component: ContentBrickDetail,
      meta: {
         title: "Create Material Compliance",
         requiresAuth: true,
      },
      props: { cbType: ContentBrickType.MaterialCompliance },
      name: "material-compliance-detail-new",
      navValue: RouteNavValue.MaterialCompliances,
   },
   {
      path: "/materialCompliance/detail/:id",
      component: ContentBrickDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Material Compliance Detail`,
         requiresAuth: true,
      },
      name: "material-compliance-detail",
      props: { cbType: ContentBrickType.MaterialCompliance },
      navValue: RouteNavValue.MaterialCompliances,
   },
   {
      path: "/preShipmentInstructions/overview",
      component: ContentBrickList,
      meta: {
         title: "Pre-shipment Instructions",
         requiresAuth: true,
      },
      props: { cbType: ContentBrickType.PreShipmentInstructions },
      name: "pre-shipment-instructions-list",
      navValue: RouteNavValue.PreShipmentInstructions,
   },
   {
      path: "/preShipmentInstructions/new",
      component: ContentBrickDetail,
      meta: {
         title: "Create Pre-shipment Instructions",
         requiresAuth: true,
      },
      props: { cbType: ContentBrickType.PreShipmentInstructions },
      name: "pre-shipment-instructions-detail-new",
      navValue: RouteNavValue.PreShipmentInstructions,
   },
   {
      path: "/preShipmentInstructions/detail/:id",
      component: ContentBrickDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Pre-shipment Instructions Detail`,
         requiresAuth: true,
      },
      name: "pre-shipment-instructions-detail",
      props: { cbType: ContentBrickType.PreShipmentInstructions },
      navValue: RouteNavValue.PreShipmentInstructions,
   },
   {
      path: "/subContentBricks/overview",
      component: SubContentBrickList,
      meta: {
         title: "Sub Content Bricks",
         requiresAuth: true,
      },
      name: "sub-content-brick-list",
      navValue: RouteNavValue.SubContentBricks,
   },
   {
      path: "/subContentBricks/detail/:id",
      component: SubContentBrickDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Sub Content Brick Detail`,
         requiresAuth: true,
      },
      name: "sub-content-brick-detail",
      navValue: RouteNavValue.SubContentBricks,
   },
   {
      path: "/subContentBricks/detail/code/:code",
      component: SubContentBrickDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Sub Content Brick Detail`,
         requiresAuth: true,
      },
      name: "sub-content-brick-detail-code",
      navValue: RouteNavValue.SubContentBricks,
   },
   {
      path: "/subContentBricks/new",
      component: SubContentBrickDetail,
      meta: {
         title: "Create Sub Content Brick",
         requiresAuth: true,
      },
      name: "sub-content-brick-detail-new",
      navValue: RouteNavValue.SubContentBricks,
   },
   {
      path: "/units/overview",
      component: UnitsList,
      meta: {
         title: "Units",
         requiresAuth: true,
      },
      name: "units-list",
      navValue: RouteNavValue.Units,
   },
   {
      path: "/units/detail/:id",
      component: UnitDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Unit Detail`,
         requiresAuth: true,
      },
      name: "unit-detail",
      navValue: RouteNavValue.Units,
   },
   {
      path: "/units/new",
      component: UnitDetail,
      meta: {
         title: "Create Unit",
         requiresAuth: true,
      },
      name: "unit-detail-new",
      navValue: RouteNavValue.Units,
   },
   {
      path: "/unitTypes/overview",
      component: UnitsTypeList,
      meta: {
         title: "Unit Types",
         requiresAuth: true,
      },
      name: "units-type-list",
      navValue: RouteNavValue.UnitTypes,
   },
   {
      path: "/unitTypes/detail/:id",
      component: UnitTypeDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Unit Type Detail`,
         requiresAuth: true,
      },
      name: "unit-type-detail",
      navValue: RouteNavValue.UnitTypes,
   },
   {
      path: "/unitTypes/new",
      component: UnitTypeDetail,
      meta: {
         title: "Create Unit Type",
         requiresAuth: true,
      },
      name: "unit-type-detail-new",
      navValue: RouteNavValue.UnitTypes,
   },
   {
      path: "/regularExpressions/overview",
      component: RegularExpressionList,
      meta: {
         title: "Formatting",
         requiresAuth: true,
      },
      name: "regular-expression-list",
      navValue: RouteNavValue.RegularExpressions,
   },
   {
      path: "/regularExpressions/detail/:id",
      component: RegularExpressionDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Formatting detail`,
         requiresAuth: true,
      },
      name: "regular-expression-detail",
      navValue: RouteNavValue.RegularExpressions,
   },
   {
      path: "/regularExpressions/new",
      component: RegularExpressionDetail,
      meta: {
         title: "Create Formatting",
         requiresAuth: true,
      },
      name: "regular-expression-detail",
      navValue: RouteNavValue.RegularExpressions,
   },
   {
      path: "/tasks/overview",
      component: TaskList,
      meta: {
         title: "Tasks Overview",
         requiresAuth: true,
      },
      name: "task-list",
      navValue: RouteNavValue.Tasks,
   },
   {
      path: "/projects/:projectId/tasks/detail/:taskId", // used in Notifications, dont forget to update BE!
      component: TaskDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Task Detail`,
         requiresAuth: true,
      },
      name: "task-detail",
      navValue: RouteNavValue.Tasks,
   },
   {
      path: "/projects/code/:projectCode/tasks/detail/:taskId", // used in Notifications, dont forget to update BE!
      component: TaskDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Task Detail`,
         requiresAuth: true,
      },
      name: "task-detail",
      navValue: RouteNavValue.Tasks,
   },
   {
      path: "/tasks/detail/code/:taskCode",
      component: TaskDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Task Detail`,
         requiresAuth: true,
      },
      name: "task-detail",
      navValue: RouteNavValue.Tasks,
   },
   {
      path: "/publicHolidays/overview",
      component: PublicHolidays,
      meta: {
         title: "Public Holidays",
         requiresAuth: true,
      },
      name: "public-holidays",
      navValue: RouteNavValue.PublicHolidays,
   },
   {
      path: "/publicHolidays/detail/:id",
      component: PublicHolidayDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Public Holiday Detail`,
         requiresAuth: true,
      },
      name: "public-holiday-detail",
      navValue: RouteNavValue.PublicHolidays,
   },
   {
      path: "/publicHolidays/new",
      component: PublicHolidayDetail,
      meta: {
         title: "Create Public Holiday",
         requiresAuth: true,
      },
      name: "public-holiday-detail-new",
      navValue: RouteNavValue.PublicHolidays,
   },
   {
      path: "/translations/overview",
      component: Translations,
      meta: {
         title: "Translations",
         requiresAuth: true,
      },
      name: "translations",
      navValue: RouteNavValue.Translations,
   },
   {
      path: "/usageTypes/overview",
      component: UsageTypes,
      meta: {
         title: "Usage Types",
         requiresAuth: true,
      },
      name: "usage-types",
      navValue: RouteNavValue.UsageTypes,
   },
   {
      path: "/usageTypes/detail/:id",
      component: UsageTypeDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Usage Type Detail`,
         requiresAuth: true,
      },
      name: "usage-type-detail",
      navValue: RouteNavValue.UsageTypes,
   },
   {
      path: "/usageTypes/new",
      component: UsageTypeDetail,
      meta: {
         title: "Create Usage Type",
         requiresAuth: true,
      },
      name: "usage-type-detail-new",
      navValue: RouteNavValue.UsageTypes,
   },
   {
      path: "/automaticImports/overview",
      component: AutomaticImportOverview,
      meta: {
         title: "Automatic Imports",
         requiresAuth: true,
      },
      name: "automatic-import-overview",
      navValue: RouteNavValue.AutomaticImports,
   },
   {
      path: "/automaticImports/detail/:id",
      component: AutomaticImportDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Automatic Import Detail`,
         requiresAuth: true,
      },
      name: "automatic-import-detail",
      navValue: RouteNavValue.AutomaticImports,
   },
   {
      path: "/automaticImports/new",
      component: AutomaticImportDetail,
      meta: {
         title: `Create Automatic Import`,
         requiresAuth: true,
      },
      name: "automatic-import-detail-new",
      navValue: RouteNavValue.AutomaticImports,
   },
   {
      path: "/generalDataModels/overview",
      component: LibraryList,
      meta: {
         title: "General Data Models",
         requiresAuth: true,
      },
      name: "library-list",
      navValue: RouteNavValue.GeneralLibrary,
   },
   {
      path: "/generalDataModels/detail/:id",
      component: LibraryDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - General Data Model Detail`,
         requiresAuth: true,
      },
      name: "library-detail",
      navValue: RouteNavValue.GeneralLibrary,
   },
   {
      path: "/generalDataModels/detail/:id/node/:nodeId",
      component: LibraryDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - General Data Model Detail`,
         requiresAuth: true,
      },
      name: "library-detail-node",
      navValue: RouteNavValue.GeneralLibrary,
   },
   {
      path: "/generalDataModels/new",
      component: LibraryDetail,
      meta: {
         title: "Create General Data Model",
         requiresAuth: true,
      },
      name: "library-detail-new",
      navValue: RouteNavValue.GeneralLibrary,
   },
   {
      path: "/enactmentPriorityLabels/overview",
      component: EnactmentPriorityLabelList,
      meta: {
         title: "Enactment Priority Labels",
         requiresAuth: true,
      },
      name: "enactment-priority-label-overview",
      navValue: RouteNavValue.EnactmentPriorityLabels,
   },
   {
      path: "/enactmentPriorityLabels/detail/:id",
      component: EnactmentPriorityLabelDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Enactment Priority Label Detail`,
         requiresAuth: true,
      },
      name: "enactment-priority-label-detail",
      navValue: RouteNavValue.EnactmentPriorityLabels,
   },
   {
      path: "/enactmentPriorityLabels/new",
      component: EnactmentPriorityLabelDetail,
      meta: {
         title: "Create Enactment Priority Label",
         requiresAuth: true,
      },
      name: "enactment-priority-label-detail-new",
      navValue: RouteNavValue.EnactmentPriorityLabels,
   },
   {
      path: "/Administration" /*to-do remove unused component?*/,
      component: Administration,
      meta: {
         title: "Administration",
         requiresAuth: true,
      },
      name: "administration",
      navValue: RouteNavValue.Administration,
   },
   {
      path: "/NotificationHistory",
      component: NotificationHistory,
      meta: {
         title: "Notifications",
         requiresAuth: true,
      },
      name: "notification-history",
   },
   {
      path: "/domains/detail/:id",
      component: DomainDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Domain Detail`,
         requiresAuth: true,
      },
      name: "domain-detail",
      navValue: RouteNavValue.Domains,
   },
   {
      path: "/domains/new",
      component: DomainDetail,
      meta: {
         title: `${DynamicTitlePlaceholder} - Domain Detail`,
         requiresAuth: true,
      },
      name: "domain-detail-new",
      navValue: RouteNavValue.Domains,
   },
] as const;

export const routes: {
   path: string;
   component: unknown;
   name: string;
   meta: object;
   navValue?: RouteNavValue;
   props?: object;
}[] = [...routesDefs];

type RoutePath = (typeof routesDefs)[number]["path"];
export type RoutePathWithParams = RoutePath & `${string}:${string}`;
export type RoutePathWithoutParams = Exclude<RoutePath, RoutePathWithParams>;

export type RouteName = (typeof routesDefs)[number]["name"];
export type RouteNameWithParams = ((typeof routesDefs)[number] & { path: RoutePathWithParams })["name"];
export type RouteNameWithoutParams = Exclude<RouteName, RouteNameWithParams>;

export const routesByName = Object.fromEntries(routes.map((x) => [x.name, x]));

export function location(routeName: RouteNameWithoutParams, idOrLocationProps?: Omit<Location, "name">);
export function location(routeName: RouteNameWithParams, idOrLocationProps: string | Omit<Location, "name">);
export function location(routeName: RouteName, idOrLocationProps: string | Omit<Location, "name"> = {}): Location {
   return {
      ...(typeof idOrLocationProps === "object" ? idOrLocationProps : { params: { id: idOrLocationProps } }),
      name: routeName,
   };
}
