<template>
   <v-dialog v-if="showDialog" v-model="showDialog" width="75%" persistent scrollable>
      <v-card>
         <add-reference-dialog-header
            :title="title"
            :newItemRoute="newItemRoute"
            :clickReload="reload"
            :clickClose="clickClose"
         >
            <v-tabs v-model="selectedTab" color="error" centered icons-and-text show-arrows>
               <v-tabs-slider></v-tabs-slider>

               <v-tab v-for="item in shownTabItems" :key="item.id" :disabled="isTabDisabled(item)">
                  {{ item.title }}
                  <v-icon color="red">{{ item.icon }}</v-icon>
               </v-tab>
            </v-tabs>
         </add-reference-dialog-header>
         <v-card-text class="pb-0">
            <v-btn-toggle
               v-if="showContentTypeSelect"
               v-model="selectedContentType"
               label="Content type"
               class="mr-3"
               dense
               mandatory
               color="red"
            >
               <v-btn class="transparent-background" :value="PbbQueryContentType.Acquisition" :disabled="disabled">
                  Acquisition
               </v-btn>
               <v-btn class="transparent-background" :value="PbbQueryContentType.Definition" :disabled="disabled">
                  Definition
               </v-btn>
            </v-btn-toggle>
            <v-tabs-items v-model="selectedTab">
               <v-tab-item v-if="showDataModelSelection">
                  <v-row>
                     <v-col class="pt-0">
                        <v-text-field
                           v-model="searchQuery"
                           append-icon="mdi-magnify"
                           label="Search"
                           color="red darken-2"
                           single-line
                           hide-details
                           autofocus
                           @input="searchDebounced()"
                        />
                     </v-col>
                  </v-row>
                  <table-filter
                     v-model="filter"
                     :entityFilter="filter"
                     :currentReferences="dataModels"
                     :entityType="entityType"
                     :useLocalStorage="false"
                     :showColumnSelection="false"
                     :hideActiveStateFilter="true"
                     :domain="domain"
                     :headers="headers"
                     :additionalFilterText="additionalFilterText"
                     @filter="loadDataModels()"
                     @tags-prepared="setTagsPrepared"
                     @selected-columns="setColumns"
                     @reset-filter="resetFilter"
                  >
                     <v-col v-if="!isDomainDataModel" class="p-0 pt-2" cols="2">
                        <v-select
                           v-model="filter.domains"
                           :items="domainSelectionOptions"
                           item-text="displayText"
                           item-value="id"
                           label="Domains"
                           multiple
                           clearable
                           @change="loadDataModels()"
                        ></v-select>
                     </v-col>
                  </table-filter>
                  <v-data-table
                     v-model="selectedDataModels"
                     :headers="headers"
                     :items="shownDataModels"
                     :loading="loadingData"
                     :search="searchQuery"
                     :single-select="!multiple"
                     show-select
                     dense
                     :server-items-length="pagingTotalDocuments"
                     :options.sync="dataTableOptions"
                     :footer-props="footerProps"
                     @item-selected="onSelectedDataModelChanged"
                     @click:row="onRowClicked"
                  >
                     <template #header.data-table-select="{}">
                        <!-- Hide select all checkbox -> server-side pagination -->
                     </template>
                     <template #item.data-table-select="{ isSelected, select }">
                        <v-simple-checkbox
                           :value="isSelected"
                           :disabled="disabled"
                           ui-test-data="item-checkbox"
                           @input="disabled || select($event)"
                        ></v-simple-checkbox>
                     </template>
                     <template v-if="newItemRoute" #item.code="{ item }">
                        <span class="gates-list-pbb-code d-inline">
                           {{ item.code }}
                        </span>
                     </template>
                     <template v-if="newItemRoute" #item.displayText="{ item }">
                        <router-link :to="getDataModelDetailRouteValue(item)" class="black--text">
                           <v-icon>mdi-link</v-icon>
                        </router-link>
                        <span class="gates-list-pbb-code d-inline">
                           {{ item.displayText }}
                        </span>
                     </template>
                     <template v-if="newItemRoute" #item.domains="{ item }">
                        <router-link :to="getDataModelDetailRouteValue(item)" class="black--text">
                           <span class="gates-list-pbb-code d-inline">
                              {{ getDomainNames(item.domains) }}
                           </span>
                        </router-link>
                     </template>
                  </v-data-table>
               </v-tab-item>
               <v-tab-item eager>
                  <spinner v-if="loadingDataModels" />
                  <domain-data-model-tree-selection
                     v-else-if="dataModelTreeNode"
                     :dataModelNode="dataModelTreeNode"
                     :dataModelCode="selectedDataModelCode"
                     :dataModelId="selectedDataModelId"
                     :init-selected-data-model-node-id="passedInitSelectedDataModelNodeId"
                     :init-selected-data-model-node-ids="passedInitSelectedDataModelNodeIds"
                     :show-selected-path="true"
                     :showPreserveDataModelNodePath="showPreserveDataModelNodePath"
                     :showPreserveParentContentPath="showPreserveParentContentPath"
                     :isParentGeneralDataModelReference="isParentGeneralDataModelReference"
                     :preserveDataModelNodePath.sync="preserveSelectedDataModelNodePath"
                     :preserveParentContentPath.sync="preserveParentContentPath"
                     :dataModelNodePath.sync="selectedDataModelNodePath"
                     :showAddRootNodeLevelOnly="showAddRootNodeLevelOnly"
                     :addRootNodeLevelOnly.sync="addRootNodeLevelOnly"
                     :showDataModelTreeSwitches="showDataModelTreeSwitches"
                     :isNodeSelectable="isNodeSelectable"
                     :showDotsMenu="false"
                     :allow-changes="false"
                     :multiple="multiple"
                     :hasChanges="hasDialogChanges"
                     :translations="routeTranslations"
                     :dataModelTreeOwner="DataModelTreeOwner.DataModelNodeSelectionDialog"
                     @selectedDataModelNodeChanged="onSelectedDataModelNodeChanged"
                     @selectedDataModelNodesChanged="onSelectedDataModelNodesChanged"
                  />
                  <span class="red--text" :style="{ visibility: isNodeTypeNoteShown ? 'visible' : 'hidden' }">
                     {{ nodeTypeNoteText }}
                  </span>
               </v-tab-item>
               <v-tab-item v-if="showContentBrickFieldSelection" eager>
                  <span>Select a CB field</span>
                  <v-select
                     v-model="selectedContentBrickField"
                     :items="contentBrickFieldReferences"
                     label="Content Brick field"
                     :item-text="getContentBrickFieldText"
                     return-object
                  ></v-select>
               </v-tab-item>
            </v-tabs-items>
         </v-card-text>

         <v-card-actions>
            <!-- Going back by a step -->
            <v-btn
               v-if="(showDataModelSelection && selectedTab > 0) || (showContentBrickFieldSelection && selectedTab > 0)"
               class="error"
               text
               @click="goBack()"
            >
               Back
            </v-btn>
            <v-spacer></v-spacer>
            <!-- Going to the node selection tab from DataModel selection tab -->
            <v-btn
               v-if="showDataModelSelection && selectedTab === 0 && selectedDataModels.length <= 1"
               key="next-btn"
               class="error"
               text
               :disabled="loadingDataModels || selectedDataModels.length < 1"
               ui-test-data="next-btn"
               @click="selectTab(1)"
            >
               Next
            </v-btn>
            <!-- Going to the CB field selection tab from node selection tab -->
            <v-btn
               v-if="showContentBrickFieldSelection && selectedTab === 0"
               key="next-btn"
               class="error"
               text
               :disabled="loadingContentBrickFields || !selectedDataModelNode || !isSelectedNodeSelectable"
               @click="selectTab(1)"
            >
               Next
            </v-btn>
            <!-- Adding root nodes of selected DMs directly from DataModel selection tab -->
            <v-btn
               v-if="showDataModelSelection && selectedTab === 0 && selectedDataModels.length > 1"
               key="add-roots-btn"
               class="error"
               text
               :disabled="loadingDataModels"
               :loading="loadingSelectingNode"
               @click="addDataModelNode()"
            >
               Add root nodes
            </v-btn>
            <!-- Adding selected nodes from the node selection tab -->
            <v-btn
               v-if="
                  (selectedTab === 1 && showDataModelSelection && !showContentBrickFieldSelection) ||
                  (selectedTab === 1 && showContentBrickFieldSelection) ||
                  (!showDataModelSelection && !showContentBrickFieldSelection)
               "
               key="add-btn"
               class="error"
               text
               :disabled="isSaveButtonDisabled || disabled"
               :loading="loadingSelectingNode"
               ui-test-data="add-btn"
               @click="addDataModelNode()"
            >
               <v-icon v-if="addIcon">{{ addIcon }}</v-icon>
               {{ saveButtonText }}
            </v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, Watch } from "vue-property-decorator";
// TODO check imports
import ViewItem from "@models/view/ViewItem";
import DomainDataModelDto from "@models/PBB/DomainDataModelDto";
import PbbContentDto from "@models/PBB/pbbContentDto";
import {
   IDataModelDefinition,
   IDataModelNode,
   IItemReference,
   LibraryDataModelApi,
   DomainDataModelApi,
   QueryOptionsOfLibraryDataModelFilterOptions,
   QueryOptionsOfDomainDataModelFilterOptions,
   DomainDataModelFilterOptions,
   LibraryDataModelFilterOptions,
   EntityType,
   DataModelDefinition,
   DataModelNode,
   DataModelNodeType,
   ContentBrickDefinitionApi,
   ContentBrickFieldType,
   ContentBrickFieldDefinition,
   ItemReference,
   IContentBrickFieldDefinitionReference,
   ContentBrickFieldDefinitionReference,
   PbbQueryContentType,
   EntityStatus,
   IItemReferenceDomain,
   Domain,
} from "@backend/api/pmToolApi";
import GlobalStore from "@backend/store/globalStore";
import IDataModelTreePair from "@models/dataModel/IDataModelTreePair";
import IDataModelNodePair from "@models/dataModel/IDataModelNodePair";
import DataModelTreeOwner from "@models/dataModel/DataModelTreeOwner";
import Events from "@models/shared/Events";
import EventBus from "@backend/EventBus";
import OverviewBase from "@components/Shared/Base/overview-base.vue";
import TableFilter from "@components/Shared/table-filter.vue";
import { clone, debounce, isEqual } from "lodash";
import AddReferenceDialogHeader from "@components/Shared/add-reference-dialog-header.vue";
import EnumUtils from "@utils/EnumUtils";
import DataModelUtils from "@utils/DataModelUtils";
import DomainModel from "@models/domain/domainModel";
import Spinner from "@components/Shared/spinner.vue";
import RouterUtils from "@utils/RouterUtils";
import { DataOptions } from "vuetify";
import DomainCachedApi from "@backend/store/apiCache/DomainCachedApi";

@Component({
   components: {
      TableFilter,
      AddReferenceDialogHeader,
      Spinner,
      // Webpack import to allow circular refrence
      DomainDataModelTreeSelection: () =>
         import("@components/PBB/DomainDataModels/Shared/domain-data-model-tree-selection.vue"),
   },
})
export default class DataModelNodeSelectionDialog extends OverviewBase {
   @Prop({ default: false })
   showDialog: boolean;

   @Prop({ default: null })
   parent: IDataModelNode | null;

   // TODO check if necessary
   @Prop({ default: null })
   item: DomainDataModelDto | PbbContentDto | null;

   @Prop({ default: false })
   showDataModelSelection!: boolean;

   @Prop({ default: false })
   showContentBrickFieldSelection!: boolean;

   @Prop({ default: "Choose a node from a Data Model" })
   title: string;

   @Prop({ default: null })
   currentDataModelCode: string | null;

   @Prop({ default: null })
   initSelectedDataModel: ItemReference | null;

   @Prop({ default: null })
   initSelectedDataModelNodeId: string | null;

   @Prop({ default: null })
   initSelectedDataModelNodeIds: string[] | null;

   @Prop({ default: PbbQueryContentType.Acquisition })
   initContentType: PbbQueryContentType;

   @Prop({ default: false })
   isDomainDataModel: boolean;

   @Prop({ default: false })
   multiple: boolean;

   @Prop({ default: false })
   loadingSelectingNode: boolean;

   @Prop({ default: false })
   disabled: boolean;

   @Prop({ default: true })
   showAddRootNodeLevelOnly: boolean;

   @Prop({ default: true })
   showDataModelTreeSwitches: boolean;

   @Prop({ default: true })
   showPreserveDataModelNodePath: boolean;

   @Prop({ default: true })
   showPreserveParentContentPath: boolean;

   @Prop({ default: false })
   isParentGeneralDataModelReference: boolean;

   @Prop({ default: null })
   selectableNodeTypes: DataModelNodeType[] | null;

   @Prop({ default: null })
   isNodeSelectable: ((item: IDataModelNode) => true | string) | null;

   @Prop({ default: false })
   allowArrayDescendantSelection: boolean;

   @Prop({ default: null })
   dataModel: IDataModelNode | null;

   @Prop({ default: false })
   showContentTypeSelect: boolean;

   @Prop({ default: false })
   includeDrafts;

   @Prop({ default: "Add" })
   addLabel: string;

   @Prop()
   addIcon: string | undefined;

   @Prop({ default: false })
   hasChanges: boolean;

   @Prop({ default: true })
   allowEditPendingChanges: boolean;

   loadingDataModels: boolean = false;

   domain: number;
   domains: DomainModel[] | undefined = [];

   entityType: EntityType;

   PbbQueryContentType = PbbQueryContentType;
   DataModelTreeOwner = DataModelTreeOwner;

   // TODO check if necessary
   updateContent: number = 1;

   selectedTab: number = 0;
   selectedDataModelNode: IDataModelNode | null = null;
   selectedDataModelNodes: IDataModelNode[] = [];
   selectedContentType = PbbQueryContentType.Acquisition;
   dataModelTreePairs: IDataModelTreePair[] = [];
   contentBrickFieldsPairs: Map<string, ContentBrickFieldDefinition[]> = new Map<
      string,
      ContentBrickFieldDefinition[]
   >();
   tagsPrepared: boolean = false;

   loadData = this.loadDataModels;
   protected defaultDataTableOptions: Partial<DataOptions> | undefined = {
      sortBy: ["code"],
      sortDesc: [true],
   };

   @Watch("isDomainDataModel")
   onIsDomainDataModelChanged() {
      this.updateEntityType();
   }

   @Watch("showDialog", { deep: true })
   async onValueChanged() {
      if (this.showDialog) {
         this.setDefaultTab();
         this.dataModels = [];
         this.searchQuery = "";
         this.selectedDataModels = [];
         this.dataModelTreePairs = [];
         this.selectedDataModelNode = null;
         this.selectedDataModelNodes = [];
         this.preserveSelectedDataModelNodePath = false;
         this.preserveParentContentPath = false;
         this.selectedDataModelNodePath = [];
         this.addRootNodeLevelOnly = false;
         this.selectedContentType = this.initContentType;

         if (this.dataModel && this.currentDataModelCode) {
            // Data model is passed via props
            var dataModelReference = new ItemReference({
               displayText: undefined,
               code: this.currentDataModelCode,
               id: "",
               entityStatus: undefined,
               lastModified: undefined,
            });
            this.dataModelTreePairs.push({
               dataModel: dataModelReference,
               dataModelTree: new DataModelDefinition({
                  root: new DataModelNode(this.dataModel),
               }),
            });
            this.selectedDataModels = [dataModelReference];
         } else {
            if (this.showDataModelSelection) {
               // Data model is unknown, load all data model references to show table
               await this.loadDataModels();

               if (this.initSelectedDataModelCode) {
                  const dataModel = this.dataModels.find((dm) => dm.code === this.initSelectedDataModelCode);
                  if (dataModel) {
                     this.selectedDataModels = dataModel ? [dataModel] : [];

                     if (!this.isDomainDataModel) {
                        await this.loadLibraryDataModel(dataModel);
                     } else {
                        await this.loadDomainDataModel(dataModel);
                     }
                  }
               }
            } else {
               if (this.initSelectedDataModel) {
                  this.selectedDataModels = [this.initSelectedDataModel];
                  if (!this.isDomainDataModel) {
                     await this.loadLibraryDataModel(this.initSelectedDataModel);
                  } else {
                     await this.loadDomainDataModel(this.initSelectedDataModel);
                  }
               }
            }
         }

         // TODO is necessary?
         this.updateContent += 1;
      }
   }

   onRowClicked(item: IItemReferenceDomain): void {
      const index = this.selectedDataModels.indexOf(item);
      if (index === -1) {
         this.selectedDataModels.push(item);
      } else {
         this.selectedDataModels.splice(index, 1);
      }
   }

   get loadingData(): boolean {
      return this.loadingDataModels || this.loadingDomains;
   }

   getDataModelDetailRouteValue(entityReference: IItemReference): object {
      return RouterUtils.getDataModelDetailRouteValue(entityReference);
   }

   addDataModelNode() {
      if (this.hasChanges && !this.allowEditPendingChanges) {
         this.notifyInfo("Save Draft before editing nodes");
         return;
      }

      var dataModelReferenceNodePairs: IDataModelNodePair[] = [];
      if (this.selectedDataModels.length > 1) {
         var selectedDataModelTreePairs = this.dataModelTreePairs.filter(
            (pair) => this.selectedDataModels.findIndex((selected) => selected.code === pair.dataModel.code) > -1
         );
         dataModelReferenceNodePairs =
            this.getDataModelReferenceNodePairsFromDataModelTreePairs(selectedDataModelTreePairs);
      } else if (this.selectedDataModels.length === 1 && this.selectedDataModelNodes.length === 1 && this.multiple) {
         dataModelReferenceNodePairs = this.getDataModelReferenceNodePairs(
            this.selectedDataModel,
            this.selectedDataModelNodes[0],
            this.dataModelTreeNode,
            this.selectedContentType
         );
      } else if (this.selectedDataModels.length === 1 && !this.multiple) {
         dataModelReferenceNodePairs = this.getDataModelReferenceNodePairs(
            this.selectedDataModel,
            this.selectedDataModelNode,
            this.dataModelTreeNode,
            this.selectedContentType
         );
      } else if (
         this.selectedDataModels.length === 1 &&
         this.selectedDataModelNodes.length > 1 &&
         this.selectedDataModel
      ) {
         dataModelReferenceNodePairs = this.selectedDataModelNodes.map((node) =>
            this.getDataModelReferenceNodePair(
               this.selectedDataModel!,
               node,
               this.dataModelTreeNode!,
               this.selectedContentType
            )
         );
      }

      if (dataModelReferenceNodePairs.length > 0) {
         this.$emit(
            "dataModelNodeSelected",
            dataModelReferenceNodePairs,
            this.parent,
            this.item,
            this.preserveSelectedDataModelNodePath,
            this.selectedDataModelNodePath,
            this.addRootNodeLevelOnly,
            this.selectedContentBrickField,
            this.preserveParentContentPath
         );
      }
   }

   //-------------- Content Brick fields ------------
   selectedContentBrickField: IContentBrickFieldDefinitionReference | null = null;
   loadingContentBrickFields: boolean = false;

   get contentBrickFieldReferences(): IContentBrickFieldDefinitionReference[] {
      var id = this.selectedDataModelNode?.contentBrick?.id;
      if (id) {
         var fields: ContentBrickFieldDefinition[] | undefined = this.contentBrickFieldsPairs[id];
         if (fields) {
            return fields
               .filter((field) => ContentBrickFieldType.Integer === field.type)
               .map(
                  (field) =>
                     new ContentBrickFieldDefinitionReference({
                        id: field.id,
                        identifier: field.identifier,
                        displayText: field.name,
                        code: undefined,
                        entityStatus: undefined,
                        lastModified: undefined,
                     })
               );
         }
      }

      return [];
   }

   getContentBrickFieldText(field: IContentBrickFieldDefinitionReference): string {
      return `${field.displayText} (${field.identifier})`;
   }

   //------------- Selectable node types --------------
   get isNodeTypeNoteShown(): boolean {
      return !this.isSelectedNodeSelectable;
   }

   get nodeTypeNoteText(): string | undefined {
      if (this.isNodeSelectable && !!this.selectedDataModelNode) {
         const isSelectableResult = this.isNodeSelectable(this.selectedDataModelNode);
         if (isSelectableResult !== true) {
            return isSelectableResult;
         }
      }

      if (this.selectableNodeTypes) {
         var items = EnumUtils.getEnumItems(DataModelNodeType);
         var types = items.filter((item) => this.selectableNodeTypes!.findIndex((type) => type === item.value) > -1);
         var message = "Please select a node of type: " + types.map((type) => type.text).join(", ");

         if (!this.allowArrayDescendantSelection && this.isSelectedNodeDescendantOfArray) {
            message = message + ", which is not a descendant of an array";
         }
         return message;
      }

      return undefined;
   }

   get isSelectedNodeSelectable(): boolean {
      if (!this.selectableNodeTypes) {
         // no filter provided, every node is selectable -> return 'true'
         return true;
      }

      return (
         !!this.selectedDataModelNode &&
         (!this.isNodeSelectable || this.isNodeSelectable(this.selectedDataModelNode) === true) &&
         this.selectableNodeTypes.findIndex((type) => type === this.selectedDataModelNode!.type) > -1 &&
         (this.allowArrayDescendantSelection || !this.isSelectedNodeDescendantOfArray)
      );
   }

   get isSelectedNodeDescendantOfArray(): boolean {
      if (!this.selectedDataModelNode) return false;

      return DataModelUtils.isTreeNodeDescendantOfArray(this.dataModel, this.selectedDataModelNode.id);
   }

   // ---- Data Models table -----
   dataModels: IItemReferenceDomain[] = [];
   selectedDataModels: IItemReferenceDomain[] = [];
   searchDataModels: string = "";
   headers: ViewItem[] = [
      { text: "Code", value: "code", class: "pmtool-table-header-fixed-sm" },
      { text: "Name", value: "displayText" },
      { text: "Domain", value: "domains" },
   ];

   get initSelectedDataModelCode(): string | null {
      return this.initSelectedDataModel?.code ?? null;
   }

   // ---- Tabs -----
   tabItems = [
      {
         icon: "mdi-database-outline",
         title: "Data Model Selection",
         id: 0,
      },
      {
         icon: "mdi-record-circle-outline",
         title: "Node Selection",
         id: 1,
      },
      {
         icon: "mdi-cube",
         title: "Field Selection",
         id: 2,
      },
   ];

   isTabDisabled(tab: { icon: string; title: string; id: number }): boolean {
      return (
         (this.showDataModelSelection &&
            tab.id === 1 &&
            (this.selectedDataModels.length !== 1 || this.loadingDataModels)) ||
         (this.showContentBrickFieldSelection &&
            tab.id === 2 &&
            (this.isSelectedNodeSelectable === false || this.loadingContentBrickFields))
      );
   }

   setDefaultTab() {
      this.selectedTab = 0;
   }

   get shownTabItems(): any[] {
      return this.showContentBrickFieldSelection
         ? this.tabItems.filter((x) => x.id === 1 || x.id === 2)
         : this.showDataModelSelection
           ? this.tabItems.filter((x) => x.id === 0 || x.id === 1)
           : this.tabItems.filter((x) => x.id === 1);
   }

   get isSaveButtonDisabled(): boolean {
      var ret =
         this.loadingDataModels ||
         (this.showDataModelSelection && this.selectedDataModels.length < 1) ||
         (!this.multiple && !this.selectedDataModelNodeId) ||
         (this.multiple && this.selectedDataModelNodes.length < 1) ||
         (this.multiple &&
            this.initSelectedDataModelNodeIds &&
            isEqual(this.initSelectedDataModelNodeIds, this.selectedDataModelNodes?.map((n) => n.id) ?? [])) ||
         !this.isSelectedNodeSelectable ||
         (this.showContentBrickFieldSelection && !this.selectedContentBrickField);
      return ret;
   }

   selectTab(tabId: number): void {
      this.selectedTab = tabId;
   }

   goBack() {
      if (this.selectedTab > 0) {
         this.selectTab(this.selectedTab - 1);
      }
   }

   // ---- Data Model ------
   preserveSelectedDataModelNodePath: boolean = false;
   preserveParentContentPath: boolean = false;
   selectedDataModelNodePath: string[][] = [];
   addRootNodeLevelOnly: boolean = false;

   get shownDataModels(): IItemReference[] {
      return this.dataModels.filter((dm) => dm.code !== this.currentDataModelCode);
   }

   get selectedDataModel(): IItemReference | undefined {
      return this.selectedDataModels.length > 0 ? this.selectedDataModels[0] : undefined;
   }

   get selectedDataModelId(): string | null {
      return this.selectedDataModel?.id ?? null;
   }

   get selectedDataModelCode(): string | null {
      return this.selectedDataModel?.code ?? null;
   }

   get selectedDataModelNodeId(): string | null {
      return this.selectedDataModelNode?.id ?? null;
   }

   get passedInitSelectedDataModelNodeId(): string | null {
      return this.initSelectedDataModelCode === this.selectedDataModelCode
         ? this.initSelectedDataModelNodeId
         : (this.dataModelTreeNode?.id ?? null);
   }

   get passedInitSelectedDataModelNodeIds(): string[] | null {
      if (this.initSelectedDataModelCode === this.selectedDataModelCode) {
         return this.initSelectedDataModelNodeIds;
      } else if (this.dataModelTreeNode?.id) {
         return [this.dataModelTreeNode.id];
      }

      return null;
   }

   get dataModelTreeNode(): IDataModelNode | undefined {
      if (this.dataModelTreePairs && this.selectedDataModels.length === 1) {
         var dataModelTreePair = this.dataModelTreePairs.find(
            (pair) => pair.dataModel.code === this.selectedDataModels[0].code
         );
         return this.getDataModelRootNode(dataModelTreePair?.dataModelTree);
      }
      return undefined;
   }

   // TODO check if necessary
   get initDataModelNodeId(): number | undefined {
      return this.item && this.isPbbContentDto(this.item) && this.item.domainDataModelNode
         ? this.item.domainDataModelNode.id
         : undefined;
   }

   // TODO check if necessary
   get isEdit(): boolean {
      return this.item !== null;
   }

   get saveButtonText(): string {
      if (this.isEdit) {
         return "Save";
      }

      if (this.showContentBrickFieldSelection) {
         return "Choose";
      }

      return this.addLabel;
   }

   setTagsPrepared(value: boolean) {
      this.tagsPrepared = value;
   }

   async onSelectedDataModelChanged(value: { item: IItemReference; value: boolean }) {
      if (value.item.id && value.value === true) {
         if (!this.isDomainDataModel) {
            await this.loadLibraryDataModel(value.item);
         } else {
            await this.loadDomainDataModel(value.item);
         }
      }
   }

   async onSelectedDataModelNodeChanged(selectedNode: IDataModelNode | null, isRoot: boolean) {
      if (this.showContentBrickFieldSelection && selectedNode?.type === DataModelNodeType.ContentBrick) {
         // load CB fields first, in order to make sure UI is updated correctly with new data (setting 'selectedDataModelNode' triggers UI refresh -> need to have new CB fields data by then)
         await this.loadContentBrickFields(selectedNode.contentBrick?.id);
      }
      this.selectedDataModelNode = selectedNode;
   }

   onSelectedDataModelNodesChanged(selectedNodes: IDataModelNode[], isRoot: boolean) {
      this.selectedDataModelNodes = selectedNodes;
   }

   /**
    * Transforms given pairs of DataModel reference and DataModel tree to pairs of DataModel reference and its root node
    * @param dataModelTreePairs Pairs to transform
    * @returns Pairs of DataModel reference and its root node
    */
   getDataModelReferenceNodePairsFromDataModelTreePairs(
      dataModelTreePairs: IDataModelTreePair[]
   ): IDataModelNodePair[] {
      return dataModelTreePairs.map((pair) => this.getDataModelReferenceNodePairFromDataModelTreePair(pair));
   }

   /**
    * Transforms given pair of DataModel reference and DataModel tree to a pair of DataModel reference and its root node
    * @param dataModelTreePair Pair to transform
    * @returns Pair of DataModel reference and its root node
    */
   getDataModelReferenceNodePairFromDataModelTreePair(dataModelTreePair: IDataModelTreePair): IDataModelNodePair {
      let rootNode = this.getDataModelRootNode(dataModelTreePair.dataModelTree)!;
      return this.getDataModelReferenceNodePair(dataModelTreePair.dataModel, rootNode, rootNode);
   }

   /**
    * Transforms given DataModel reference and DataModel node to a list of DataModel reference and node pairs
    * @param dataModel DataModel reference to transform
    * @param dataModelNode DataModel node to transform
    * @param dataModelRootNode DataModel root node of the node to transform
    * @returns List of DataModel reference and node pairs
    */
   getDataModelReferenceNodePairs(
      dataModel: IItemReference | undefined | null,
      dataModelNode: IDataModelNode | undefined | null,
      dataModelRootNode: IDataModelNode | undefined | null,
      contentType: PbbQueryContentType
   ): IDataModelNodePair[] {
      return dataModel && dataModelNode && dataModelRootNode
         ? [this.getDataModelReferenceNodePair(dataModel, dataModelNode, dataModelRootNode, contentType)]
         : [];
   }

   /**
    * Transforms given DataModel reference and DataModel node to a pair of DataModel reference and node
    * @param dataModel DataModel reference to transform
    * @param dataModelNode DataModel node to transform
    * @param dataModelRootNode DataModel root node of the node to transform
    * @returns Pair of DataModel reference and node
    */
   getDataModelReferenceNodePair(
      dataModel: IItemReference,
      dataModelNode: IDataModelNode,
      dataModelRootNode: IDataModelNode,
      contentType: PbbQueryContentType
   ): IDataModelNodePair {
      return {
         dataModel: dataModel,
         dataModelNode: dataModelNode,
         dataModelRootNode: dataModelRootNode,
         dataModelContentType: contentType,
      };
   }

   /**
    * Gets the root node of the given DataModel tree
    * @param dataModel DataModel tree to get the root node from
    * @returns Root node of the given DataModel tree
    */
   getDataModelRootNode(dataModel: IDataModelDefinition | undefined): IDataModelNode | undefined {
      return dataModel?.root;
   }

   /**
    * Determines whether or not is given DataModel reference present in loaded DataModel pairs
    * @param dataModel DataModel reference to check
    * @return True if loaded already, false otherwise
    */
   isDataModelLoaded(dataModel: IItemReference): boolean {
      return this.dataModelTreePairs.findIndex((pair) => pair.dataModel.code === dataModel.code) > -1;
   }

   /**
    * Determines whether or not are given ContentBrick fields present in loaded ContentBrick field pairs
    * @param id ID of ContentBrick to check
    * @return True if loaded already, false otherwise
    */
   areContentBrickFieldsLoaded(id: string): boolean {
      return this.contentBrickFieldsPairs.has(id);
   }

   // TODO check if necessary
   isDomainDataModelDto(model: DomainDataModelDto | PbbContentDto): model is DomainDataModelDto {
      return (<DomainDataModelDto>model).isReference !== undefined;
   }

   // TODO check if necessary
   isPbbContentDto(model: DomainDataModelDto | PbbContentDto): model is PbbContentDto {
      return (<PbbContentDto>model).contentType !== undefined;
   }

   getDomainNames(itemDomains: number[]) {
      if (itemDomains && itemDomains.length > 0) {
         return this.domains
            ?.filter((x) => itemDomains.includes(x.id)) // Find appropriete domains
            .map((d) => d.displayText) // Gets only domain name
            .join(","); // join to string
      }
      return "";
   }

   // ------- Dialog control --------

   hideDialog(): void {
      this.$emit("hideDialog");
   }

   clickClose() {
      this.hideDialog();
   }

   get hasDialogChanges(): boolean {
      if (
         this.initSelectedDataModelCode !== this.selectedDataModelCode ||
         this.initSelectedDataModelNodeId !== this.selectedDataModelNodeId ||
         this.initContentType !== this.selectedContentType ||
         this.selectedDataModelNodes.length > 0
      ) {
         return true;
      }

      return false;
   }

   get domainSelectionOptions() {
      let options = this.domains as any;
      return options;
   }

   async reload() {
      this.dataModelTreePairs = [];

      let selected = clone(this.selectedDataModels);
      await this.loadDataModels();

      let newSelectedDataModels: IItemReference[] = [];

      // TODO: #36074
      await Promise.all(
         selected.map((x) => {
            let matchedData = this.dataModels.find((y) => x.code == y.code);
            if (matchedData) {
               newSelectedDataModels.push(matchedData);

               if (!this.isDomainDataModel) {
                  return this.loadLibraryDataModel(matchedData);
               } else {
                  return this.loadDomainDataModel(matchedData);
               }
            }
            return Promise.resolve();
         })
      );

      this.selectedDataModels = newSelectedDataModels;
   }

   get newItemRoute(): string {
      return `/${this.isDomainDataModel ? "domain" : "general"}DataModels/new`;
   }

   // ---- Filters ----
   searchQuery: string = "";
   filter: DomainDataModelFilterOptions | LibraryDataModelFilterOptions = this.initFilter();

   initFilter() {
      if (this.isDomainDataModel) {
         return new DomainDataModelFilterOptions({
            entityStatus: EntityStatus.Active,
            entityCodes: [],
            lastOnly: true,
            tags: [],
            searchIdentifiers: [],
            lastVisited: undefined,
            searchQuery: this.searchQuery ? this.searchQuery : undefined,
            createdBy: undefined,
            domain: GlobalStore.getDomain(),
         });
      } else {
         return new LibraryDataModelFilterOptions({
            entityStatus: EntityStatus.Active,
            entityCodes: [],
            lastOnly: true,
            tags: [],
            searchIdentifiers: [],
            lastVisited: undefined,
            searchQuery: this.searchQuery ? this.searchQuery : undefined,
            createdBy: undefined,
            domains: [GlobalStore.getDomain()],
         });
      }
   }

   private searchDebounced = debounce(() => this.search(), 200);

   search() {
      if (this.searchQuery.length >= 3) {
         this.filter.searchQuery = this.searchQuery;
         void this.loadDataModels();
      } else if (this.filter.searchQuery && !this.searchQuery) {
         this.filter.searchQuery = undefined;
         void this.loadDataModels();
      }
   }

   async loadDataModels() {
      if (!this.isDomainDataModel) {
         await this.loadLibraryDataModels();
      } else {
         await this.loadDomainDataModels();
      }
   }

   resetFilter() {
      this.filter = this.initFilter();
   }

   get additionalFilterText() {
      let result: string[] = [];

      if (!this.isDomainDataModel) {
         if (
            this.domainSelectionOptions &&
            this.filter?.domains !== null &&
            this.filter?.domains !== undefined &&
            this.filter?.domains.length > 0
         ) {
            let filteredDomains = this.domainSelectionOptions.filter((x) =>
               this.filter.domains.some((y) => y === x.id)
            );

            if (filteredDomains?.length) {
               filteredDomains.forEach((domain) => {
                  result.push(domain.displayText);
               });
            }
         }
      }

      return result;
   }

   updateEntityType() {
      this.entityType = this.isDomainDataModel
         ? EntityType.DomainDataModelDefinition
         : EntityType.LibraryDataModelDefinition;
      this.resetFilter();
   }

   // ----- Domains -----
   loadingDomains: boolean = false;

   sortDomains(domains: Domain[]): Domain[] {
      return domains.sort((a, b) => {
         if (a.name === undefined) {
            return 1;
         } else if (b.name === undefined) {
            return -1;
         }
         return a.name.localeCompare(b.name);
      });
   }

   // ---- API -----
   async loadLibraryDataModels(): Promise<void> {
      this.loadingDataModels = true;
      try {
         // prepare api call parameters
         let queryOptions = new QueryOptionsOfLibraryDataModelFilterOptions();
         queryOptions.filter = this.filter;
         this.OnBeforePagedQuery(queryOptions);

         let result = await LibraryDataModelApi.getLibraryDataModelDefinitionReferences(queryOptions);
         this.dataModels = result.documents;

         // preserve pagination parameters
         this.OnAfterPagedQuery(result.continuationToken, result.totalDocumentsCount);
      } catch (error) {
         this.notifyError(error, "load", "LibraryDataModels");
      }
      this.loadingDataModels = false;
   }

   /**
    * Loads the full LibraryDataModel of the given LibraryDataModel reference.
    * Note: Loads only if DataModel is not yet loaded in the cached list @var dataModelTreePairs .
    * @param reference LDM reference of which to load the full DataModel
    */
   async loadLibraryDataModel(reference: IItemReference): Promise<void> {
      this.loadingDataModels = true;
      try {
         if (!reference?.id) throw "No selected DataModel ID defined";

         if (!this.isDataModelLoaded(reference)) {
            let dataModel = await LibraryDataModelApi.getLibraryDataModelDefinition(reference.id);
            this.dataModelTreePairs.push({
               dataModel: reference,
               dataModelTree: dataModel.dataModelDefinition!,
            });
         }
      } catch (error) {
         this.notifyError(error, "load", "LibraryDataModels");
      }
      this.loadingDataModels = false;
   }

   async loadDomainDataModels(): Promise<void> {
      this.loadingDataModels = true;
      try {
         let domain = GlobalStore.getDomain();

         // prepare api call parameters
         let queryOptions = new QueryOptionsOfDomainDataModelFilterOptions();
         queryOptions.filter = this.filter;

         if (this.includeDrafts) {
            queryOptions.filter!.entityStatus = undefined;
            queryOptions.filter!.lastActiveAndDraft = true;
         }

         this.OnBeforePagedQuery(queryOptions);

         let result = await DomainDataModelApi.getDomainDataModelDefinitionReferences(domain, queryOptions);
         this.dataModels = result.documents;

         // preserve pagination parameters
         this.OnAfterPagedQuery(result.continuationToken, result.totalDocumentsCount);
      } catch (error) {
         this.notifyError(error, "load", "DomainDataModel");
      }
      this.loadingDataModels = false;
   }

   /**
    * Loads the full DomainDataModel of the given DomainDataModel reference.
    * Note: Loads only if DataModel is not yet loaded in the cached list @var dataModelTreePairs .
    * @param reference DDM reference of which to load the full DataModel
    */
   async loadDomainDataModel(reference: IItemReference): Promise<void> {
      this.loadingDataModels = true;
      try {
         if (!reference?.id) throw "No selected domain DataModel ID defined";

         if (!this.isDataModelLoaded(reference)) {
            let dataModel = await DomainDataModelApi.getDomainDataModelDefinition(reference.id);
            this.dataModelTreePairs.push({
               dataModel: reference,
               dataModelTree: dataModel.dataModelDefinition!,
            });
         }
      } catch (error) {
         this.notifyError(error, "load", "LibraryDataModels");
      }
      this.loadingDataModels = false;
   }

   /**
    * Loads the full ContentBrick fields of the given ContentBrick by its ID.
    * Note: Loads only if ContentBrick fields are not yet loaded in the cached list @var contentBrickFieldsPairs .
    * @param id ContentBrick ID of which to load the full ContentBrick fields
    */
   async loadContentBrickFields(id: string | undefined): Promise<void> {
      this.loadingContentBrickFields = true;
      try {
         if (!id) throw "No selected ContentBrick ID defined";

         if (!this.areContentBrickFieldsLoaded(id)) {
            let fields = await ContentBrickDefinitionApi.getContentBrickFieldDefinitions(id);
            this.contentBrickFieldsPairs[id] = fields;
         }
      } catch (error) {
         this.notifyError(error, "load", "LibraryDataModels");
      }
      this.loadingContentBrickFields = false;
   }

   async loadDomains() {
      this.loadingDomains = true;
      try {
         let result = await DomainCachedApi.getDomainsForMenu();
         let accessibleDomains = result ? this.sortDomains(result) : [];

         this.domains = accessibleDomains.map((d) => ({
            id: d.domainId,
            name: d.name,
            displayText: d.name,
         }));
      } catch (error) {
         this.notifyError(error, "load", "DataModelNode selection dialog Domains");
      } finally {
         this.loadingDomains = false;
      }
   }

   //-------------- Translations --------------
   async loadTranslations() {
      await this.loadRouteTranslations("data-model-node-selection-dialog");
   }

   //---------- Component lifecycle ------------
   async mounted() {
      await this.loadDomains();
      this.domain = GlobalStore.getDomain();
      this.updateEntityType();

      void this.loadTranslations();
      EventBus.$on(Events.LanguageChanged, this.loadTranslations);
   }
}
</script>
