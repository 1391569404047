<template>
   <v-dialog v-if="showDialog" v-model="showDialog" width="75%" persistent :loading="loading">
      <v-card>
         <add-reference-dialog-header
            :title="title"
            :clickReload="clickReloadFunc"
            :clickClose="clickClose"
         ></add-reference-dialog-header>
         <v-card-text>
            <data-table-rows
               v-if="dataTable"
               :dataTable="dataTable"
               :selected.sync="selectedRowIds"
               :showSelect="true"
               :singleSelect="true"
               :showTitle="false"
               :readonly="true"
               :translations="translations"
            ></data-table-rows>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="error" text :disabled="loading" @click="save">Save</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, Watch } from "vue-property-decorator";
import { DataTable, DataTableApi, TranslationPublicModel } from "@backend/api/pmToolApi";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import AddReferenceDialogHeader from "@components/Shared/add-reference-dialog-header.vue";

// TODO: implement fully when Data table column reference is implemented
@Component({
   name: "DataTableRowSelectionDialog",
   components: {
      AddReferenceDialogHeader,
      DataTableRows: () => import("@components/DataTables/TabDetail/TableDefinition/data-table-rows.vue"),
   },
})
export default class DataTableRowSelectionDialog extends ComponentBase {
   @Prop({ default: null })
   value: string | null;

   @Prop({ required: true })
   dataTableId: string;

   @Prop({ required: true })
   showDialog: boolean;

   @Prop({ required: true })
   translations: TranslationPublicModel[];

   @Watch("showDialog", { deep: true, immediate: true })
   async onShowDialogChanged(): Promise<void> {
      if (this.showDialog) {
         // after opening reset data
         this.selectedRowId = this.value;
         await this.loadDataTable();
      }
   }

   @Watch("value")
   onValueChanged(newVal: string | null) {
      this.selectedRowId = newVal;
   }

   selectedRowId: string | null = null;

   get selectedRowIds(): string[] {
      return this.selectedRowId ? [this.selectedRowId] : [];
   }

   set selectedRowIds(value: string[]) {
      if (value.length > 1) {
         throw new Error("Selecting multiple DataTable rows is forbidden");
      }
      this.selectedRowId = value.length === 0 ? null : value[0];
   }

   get title(): string {
      return "Select Data Table rows";
   }

   clickClose() {
      this.hideDialog();
   }

   get clickReloadFunc(): (() => Promise<void>) | null {
      return this.loadDataTable;
   }

   hideDialog(): void {
      this.$emit("hideDialog");
   }

   save(): void {
      this.$emit("selected", this.selectedRowId);
   }

   loading: boolean = false;
   dataTable: DataTable | null = null;

   async loadDataTable(): Promise<void> {
      this.loading = true;
      try {
         // Call the API
         let dataTable = await DataTableApi.getDataTable(this.dataTableId);

         // Process/Save data etc.
         this.dataTable = dataTable;
      } catch (error) {
         this.notifyError(error, "load", "DataTable");
      }
      this.loading = false;
   }
}
</script>

<style scoped></style>
