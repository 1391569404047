import { ContentBrickFieldType } from "@backend/api/pmToolApi";
import { IEnumBaseProperties } from "@models/shared/IEnumBaseProperties";

export interface IContentBrickFieldTypeProperties extends IEnumBaseProperties {
   readonly queryBuilderType: string;
   readonly queryOperators?: string[];
   readonly hasUnits: boolean;
   readonly uiFormBuilderType: string | undefined;
}

export class ContentBrickFieldTypeDecorator implements IContentBrickFieldTypeProperties {
   value: ContentBrickFieldType;
   private static readonly unkown: number = -1;

   constructor(value: ContentBrickFieldType) {
      this.value = value;
   }

   get queryBuilderType(): string {
      return this.properties.queryBuilderType;
   }

   get queryOperators(): string[] | undefined {
      return this.properties.queryOperators;
   }

   get hasUnits(): boolean {
      return this.properties.hasUnits;
   }

   get translationKey(): string {
      return this.properties.translationKey;
   }

   get uiFormBuilderType(): string | undefined {
      return this.properties.uiFormBuilderType;
   }

   static get AllItems(): ContentBrickFieldTypeDecorator[] {
      return Array.from(ContentBrickFieldTypeDecorator.entityStatusProperties.keys())
         .filter(
            (status: number) =>
               status != ContentBrickFieldTypeDecorator.unkown && status != ContentBrickFieldType.DataTable // TODO: remove when DataTable CB field is implemented
         )
         .map((status: number) => new ContentBrickFieldTypeDecorator(status));
   }

   static get ValueFieldTypes(): ContentBrickFieldTypeDecorator[] {
      return [
         ContentBrickFieldType.Boolean,
         ContentBrickFieldType.Date,
         ContentBrickFieldType.DateTime,
         ContentBrickFieldType.Decimal,
         ContentBrickFieldType.Document,
         ContentBrickFieldType.Image,
         ContentBrickFieldType.Video,
         ContentBrickFieldType.Integer,
         ContentBrickFieldType.TextArea,
         ContentBrickFieldType.TextBox,
         ContentBrickFieldType.Time,
         ContentBrickFieldType.List,
         ContentBrickFieldType.MultiSelectList,
         ContentBrickFieldType.Calculated,
      ].map((x) => new ContentBrickFieldTypeDecorator(x));
   }

   static get MetadataFieldItems(): ContentBrickFieldTypeDecorator[] {
      return [
         ContentBrickFieldType.Boolean,
         ContentBrickFieldType.Date,
         ContentBrickFieldType.DateTime,
         ContentBrickFieldType.Decimal,
         ContentBrickFieldType.Integer,
         ContentBrickFieldType.TextArea,
         ContentBrickFieldType.TextBox,
         ContentBrickFieldType.Time,
         ContentBrickFieldType.List,
         ContentBrickFieldType.MultiSelectList,
      ].map((status: number) => new ContentBrickFieldTypeDecorator(status));
   }

   static get TechnicalMeasureFieldItems(): ContentBrickFieldTypeDecorator[] {
      return Array.from(ContentBrickFieldTypeDecorator.entityStatusProperties.keys())
         .filter(
            (x) =>
               x === ContentBrickFieldType.Date ||
               x === ContentBrickFieldType.DateTime ||
               x === ContentBrickFieldType.Decimal ||
               x === ContentBrickFieldType.Integer ||
               x === ContentBrickFieldType.TextArea ||
               x === ContentBrickFieldType.TextBox ||
               x === ContentBrickFieldType.Time
         )
         .map((status: number) => new ContentBrickFieldTypeDecorator(status));
   }

   static get DataTableColumnItems(): ContentBrickFieldTypeDecorator[] {
      return Array.from(ContentBrickFieldTypeDecorator.entityStatusProperties.keys())
         .filter(
            (x) =>
               x === ContentBrickFieldType.Boolean ||
               x === ContentBrickFieldType.Date ||
               x === ContentBrickFieldType.DateTime ||
               x === ContentBrickFieldType.Decimal ||
               x === ContentBrickFieldType.Integer ||
               // TODO: DataTable hidden field type reference to other table until fully implemented -->
               // x === ContentBrickFieldType.DataTable ||
               x === ContentBrickFieldType.TextBox ||
               x === ContentBrickFieldType.Time
         )
         .map((status: number) => new ContentBrickFieldTypeDecorator(status));
   }

   /**
    * Checks if current field type is equivalent to the provided UI FormBuilder type
    * @param uiFormBuilderType Type of FormBuilder control
    * @returns True, if field type is equivalent to the FormBuilder type, false otherwise
    */
   isEquivalentToUiFormBuilderType(uiFormBuilderType: string | undefined): boolean {
      return ContentBrickFieldTypeDecorator.isFieldTypeDecoratorEquivalentToUiFormBuilderType(this, uiFormBuilderType);
   }

   /**
    * Checks if provided field type is equivalent to the provided UI FormBuilder type
    * @param fieldType CB Field type
    * @param uiFormBuilderType Type of FormBuilder control
    * @returns True, if field type is equivalent to the FormBuilder type, false otherwise
    */
   static isFieldTypeEquivalentToUiFormBuilderType(
      fieldType: ContentBrickFieldType,
      uiFormBuilderType: string | undefined
   ): boolean {
      return ContentBrickFieldTypeDecorator.isFieldTypeDecoratorEquivalentToUiFormBuilderType(
         new ContentBrickFieldTypeDecorator(fieldType),
         uiFormBuilderType
      );
   }

   private static isFieldTypeDecoratorEquivalentToUiFormBuilderType(
      fieldTypeDecorator: ContentBrickFieldTypeDecorator,
      uiFormBuilderType: string | undefined
   ): boolean {
      if (!uiFormBuilderType) throw "UiFormBuilderType is required to check if field type is equvivalent";

      const expectedFieldControlType = fieldTypeDecorator.uiFormBuilderType;
      if (expectedFieldControlType === undefined) {
         return false; // field has no type defined, cannot be equivalent to any controls
      }

      return expectedFieldControlType === uiFormBuilderType; // true if field and uiFormBuilderType types do match
   }

   private get properties(): IContentBrickFieldTypeProperties {
      return (
         ContentBrickFieldTypeDecorator.entityStatusProperties.get(this.value) ??
         ContentBrickFieldTypeDecorator.entityStatusProperties.get(ContentBrickFieldTypeDecorator.unkown)!
      );
   }

   private static readonly entityStatusProperties: Map<number, IContentBrickFieldTypeProperties> = new Map<
      number,
      IContentBrickFieldTypeProperties
   >([
      [
         ContentBrickFieldType.TextBox,
         {
            queryBuilderType: "text",
            hasUnits: false,
            uiFormBuilderType: "input",
            translationKey: "contentBrickFieldType.textBox",
         },
      ],
      [
         ContentBrickFieldType.TextArea,
         {
            queryBuilderType: "text",
            hasUnits: false,
            uiFormBuilderType: "text",
            translationKey: "contentBrickFieldType.textArea",
         },
      ],
      [
         ContentBrickFieldType.Integer,
         {
            queryBuilderType: "numeric",
            hasUnits: true,
            uiFormBuilderType: "number",
            translationKey: "contentBrickFieldType.integer",
         },
      ],
      [
         ContentBrickFieldType.Decimal,
         {
            queryBuilderType: "numeric",
            hasUnits: true,
            uiFormBuilderType: "number",
            translationKey: "contentBrickFieldType.decimal",
         },
      ],
      [
         ContentBrickFieldType.Boolean,
         {
            queryBuilderType: "boolean",
            queryOperators: ["=", "<>", "equals", "does not equal"],
            hasUnits: false,
            uiFormBuilderType: "switch", // switch is type name of our custom extended control "checkbox"
            translationKey: "contentBrickFieldType.boolean",
         },
      ],
      [
         ContentBrickFieldType.Calculated,
         {
            queryBuilderType: "numeric",
            hasUnits: true,
            uiFormBuilderType: "number",
            translationKey: "contentBrickFieldType.calculated",
         },
      ],
      [
         ContentBrickFieldType.Document,
         {
            queryBuilderType: "text",
            hasUnits: false,
            uiFormBuilderType: undefined /* "fileUploader" */ /* uncomment when fileUploader is implemented */,
            translationKey: "contentBrickFieldType.document",
         },
      ],
      [
         ContentBrickFieldType.ComboBox,
         {
            queryBuilderType: "select",
            queryOperators: ["equals", "does not equal", "is not empty", "is empty"],
            hasUnits: false,
            uiFormBuilderType: "dropDown",
            translationKey: "contentBrickFieldType.comboBox",
         },
      ],
      [
         ContentBrickFieldType.DateTime,
         {
            queryBuilderType: "dateTime",
            queryOperators: ["=", "<>", ">", ">=", "<", "<="],
            hasUnits: false,
            uiFormBuilderType: "dateTime",
            translationKey: "contentBrickFieldType.dateTime",
         },
      ],
      [
         ContentBrickFieldType.Date,
         {
            queryBuilderType: "date",
            queryOperators: ["=", "<>", ">", ">=", "<", "<="],
            hasUnits: false,
            uiFormBuilderType: "dateCustom",
            translationKey: "contentBrickFieldType.date",
         },
      ],
      [
         ContentBrickFieldType.Time,
         {
            queryBuilderType: "time",
            queryOperators: ["=", "<>", ">", ">=", "<", "<="],
            hasUnits: false,
            uiFormBuilderType: "time",
            translationKey: "contentBrickFieldType.time",
         },
      ],
      // NOTE: deprecated
      // ContentBrickFieldType.Phone,
      // NOTE: deprecated
      // ContentBrickFieldType.Email,
      [
         ContentBrickFieldTypeDecorator.unkown,
         {
            queryBuilderType: "text",
            hasUnits: false,
            uiFormBuilderType: "input",
            translationKey: "contentBrickFieldType.unknown",
         },
      ],
      [
         ContentBrickFieldType.List,
         {
            queryBuilderType: "list",
            queryOperators: ["equals", "does not equal", "is not empty", "is empty"],
            hasUnits: false,
            uiFormBuilderType: "dropDown",
            translationKey: "contentBrickFieldType.list",
         },
      ],
      [
         ContentBrickFieldType.Image,
         {
            queryBuilderType: "text",
            hasUnits: false,
            uiFormBuilderType: undefined /* "fileUploader" */ /* uncomment when fileUploader is implemented */,
            translationKey: "contentBrickFieldType.image",
         },
      ],
      [
         ContentBrickFieldType.SubContentBrick,
         {
            queryBuilderType: "scb",
            queryOperators: ["equals", "does not equal", "is not empty"],
            hasUnits: false,
            uiFormBuilderType: "dropDown",
            translationKey: "contentBrickFieldType.subContentBrick",
         },
      ],
      [
         ContentBrickFieldType.MultiSelectList,
         {
            queryBuilderType: "text",
            queryOperators: ["contains", "does not equal", "is not empty", "is empty"],
            hasUnits: false,
            uiFormBuilderType: "dropDown",
            translationKey: "contentBrickFieldType.multiSelectList",
         },
      ],
      [
         ContentBrickFieldType.Video,
         {
            queryBuilderType: "text",
            hasUnits: false,
            uiFormBuilderType: undefined /* "fileUploader" */ /* uncomment when fileUploader is implemented */,
            translationKey: "contentBrickFieldType.video",
         },
      ],
      [
         ContentBrickFieldType.DataTable,
         {
            queryBuilderType: "text",
            hasUnits: false,
            uiFormBuilderType: undefined,
            translationKey: "contentBrickFieldType.dataTable",
         },
      ],
   ]);
}
