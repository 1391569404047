import { Vue } from "vue-property-decorator";
import ConfirmCloseDialogResult from "@models/shared/ConfirmCloseDialogResult";
import { AxiosKeycloak } from "@backend/axiosKeycloak";

const watcher = {
   async onRouteChange(next: any, vue: Vue, saveFunc: () => Promise<void>, toUrl?: string): Promise<void> {
      const keycloak = AxiosKeycloak.getKeycloak();
      if (!keycloak.authenticated) {
         next();
         return;
      }

      if (!vue.isRouteGuarded) {
         next();
         return;
      }

      if (vue.hasChanges) {
         const res = await vue.$refs.confirmCloseDialog.showDialogAsync();
         if (res === ConfirmCloseDialogResult.Discard) {
            next();
         } else if (res === ConfirmCloseDialogResult.Save) {
            await saveFunc();
            next();
         } else {
            // ConfirmCloseDialogResult.Cancel
            next(false);
         }
      } else {
         next();
      }
   },
   printChanges(newVal: string, oldVal: string) {
      //let newVal = JSON.stringify(newValue);
      let i = 0;
      for (; i < Math.min(newVal.length, oldVal.length); i++) {
         if (newVal[i] !== oldVal[i]) {
            break;
         }
      }
      let quoteCount = 2;
      let colon = false;
      let j = i;
      for (; j >= 0; j--) {
         if (!colon && newVal[j] == ":") {
            colon = true;
         }
         if (colon && newVal[j] == '"') {
            quoteCount--;
            if (quoteCount == 0) break;
         }
      }

      console.log("original", oldVal.substring(Math.max(j, 0), Math.min(oldVal.length, i + 40)));
      console.log("newValue", newVal.substring(Math.max(j, 0), Math.min(newVal.length, i + 40)));
   },
   setupWatch<TComponent extends Vue>(vue: TComponent, property: keyof TComponent, debug: boolean = false): void {
      vue["__copy_" + (property as string)] = JSON.stringify(vue[property]);
      vue["__watcher_" + (property as string)] = vue.$watch(
         property,
         function (newValue, oldValue) {
            if (newValue == oldValue) {
               vue.hasChanges = JSON.stringify(newValue) !== vue["__copy_" + (property as string)];
               if (debug && vue.hasChanges) {
                  watcher.printChanges(JSON.stringify(newValue), vue["__copy_" + (property as string)]);
               }
            } else {
               vue.hasChanges = false;
               vue["__copy_" + (property as string)] = JSON.stringify(vue[property]);
            }
         },
         { deep: true }
      );

      // https://stackoverflow.com/a/10311375
      window.onbeforeunload = function (e) {
         e = e || window.event;
         const keycloak = AxiosKeycloak.getKeycloak();

         if (vue.hasChanges && keycloak.authenticated) {
            // For IE and Firefox prior to version 4
            if (e) {
               e.returnValue = "There are unsaved changes, leave regardless?"; //ignored except IE
            }

            // For Safari
            return "There are unsaved changes, leave regardless?"; //ignored except IE
         }
      };
   },
   destroyWatch<TComponent extends Vue>(vue: TComponent, property: keyof TComponent): void {
      if (vue["__watcher_" + (property as string)]) {
         vue["__watcher_" + (property as string)]();
      }

      window.onbeforeunload = undefined;
   },
};
export default watcher;
