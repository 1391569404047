<template>
   <div class="d-flex">
      <template v-if="!Util.isValueField(value)">
         <v-row v-if="value.type === ContentBrickFieldType.Document" dense>
            <v-col cols="auto">
               <v-autocomplete
                  v-if="value.type === ContentBrickFieldType.Document"
                  v-model="value.documentType"
                  label="Sub Type"
                  :items="documentTypeComboItems"
                  item-value="value"
                  :readonly="readonly"
                  class="document-type-input"
               >
                  <template #item="{ item }">
                     {{ getDocumentTypeDescription(item) }}
                  </template>
                  <template #selection="{ item }">
                     {{ getDocumentTypeDescription(item) }}
                  </template>
               </v-autocomplete>
            </v-col>
            <v-col cols="auto">
               <v-text-field
                  v-if="value.type === ContentBrickFieldType.Document"
                  :ref="'minCount_' + value.id"
                  v-model="value.documentMinCount"
                  type="number"
                  label="Min"
                  :rules="documentRules.documentMinCount"
                  :readonly="readonly"
                  class="pmtool-table-header-fixed-xs"
                  @input="setDocumentInputsToValidate($refs['minCount_' + value.id], $refs['maxCount_' + value.id])"
               ></v-text-field>
            </v-col>
            <v-col cols="auto">
               <v-text-field
                  v-if="value.type === ContentBrickFieldType.Document"
                  :ref="'maxCount_' + value.id"
                  v-model="value.documentMaxCount"
                  type="number"
                  label="Max"
                  :rules="documentRules.documentMaxCount"
                  :readonly="readonly"
                  class="pmtool-table-header-fixed-xs"
                  @input="setDocumentInputsToValidate($refs['minCount_' + value.id], $refs['maxCount_' + value.id])"
               ></v-text-field>
            </v-col>
         </v-row>
         <v-row v-if="value.type === ContentBrickFieldType.Image" dense>
            <v-col cols="auto">
               <v-autocomplete
                  v-if="value.type === ContentBrickFieldType.Image"
                  v-model="value.imageType"
                  label="Sub Type"
                  :items="imageTypeComboItems"
                  item-value="value"
                  :readonly="readonly"
                  class="document-type-input"
               >
                  <template #item="{ item }">
                     {{ getDecoratorText(item) }}
                  </template>
                  <template #selection="{ item }">
                     {{ getDecoratorText(item) }}
                  </template>
               </v-autocomplete>
            </v-col>
            <v-col cols="auto">
               <v-text-field
                  v-if="value.type === ContentBrickFieldType.Image"
                  :ref="'minCount_' + value.id"
                  v-model="value.documentMinCount"
                  type="number"
                  label="Min"
                  :rules="documentRules.documentMinCount"
                  :readonly="readonly"
                  class="pmtool-table-header-fixed-xs"
                  @input="setDocumentInputsToValidate($refs['minCount_' + value.id], $refs['maxCount_' + value.id])"
               ></v-text-field>
            </v-col>
            <v-col cols="auto">
               <v-text-field
                  v-if="value.type === ContentBrickFieldType.Image"
                  :ref="'maxCount_' + value.id"
                  v-model="value.documentMaxCount"
                  type="number"
                  label="Max"
                  :rules="documentRules.documentMaxCount"
                  :readonly="readonly"
                  class="pmtool-table-header-fixed-xs"
                  @input="setDocumentInputsToValidate($refs['minCount_' + value.id], $refs['maxCount_' + value.id])"
               ></v-text-field>
            </v-col>
         </v-row>
         <v-row v-if="value.type === ContentBrickFieldType.Video" dense>
            <v-col cols="auto">
               <v-autocomplete
                  v-if="value.type === ContentBrickFieldType.Video"
                  v-model="value.videoType"
                  label="Sub Type"
                  :items="videoTypeComboItems"
                  item-value="value"
                  :readonly="readonly"
                  class="document-type-input"
               >
                  <template #item="{ item }">
                     {{ getDecoratorText(item) }}
                  </template>
                  <template #selection="{ item }">
                     {{ getDecoratorText(item) }}
                  </template>
               </v-autocomplete>
            </v-col>
            <v-col cols="auto">
               <v-text-field
                  v-if="value.type === ContentBrickFieldType.Video"
                  :ref="'minCount_' + value.id"
                  v-model="value.documentMinCount"
                  type="number"
                  label="Min"
                  :rules="documentRules.documentMinCount"
                  :readonly="readonly"
                  class="pmtool-table-header-fixed-xs"
                  @input="setDocumentInputsToValidate($refs['minCount_' + value.id], $refs['maxCount_' + value.id])"
               ></v-text-field>
            </v-col>
            <v-col cols="auto">
               <v-text-field
                  v-if="value.type === ContentBrickFieldType.Video"
                  :ref="'maxCount_' + value.id"
                  v-model="value.documentMaxCount"
                  type="number"
                  label="Max"
                  :rules="documentRules.documentMaxCount"
                  :readonly="readonly"
                  class="pmtool-table-header-fixed-xs"
                  @input="setDocumentInputsToValidate($refs['minCount_' + value.id], $refs['maxCount_' + value.id])"
               ></v-text-field>
            </v-col>
         </v-row>
      </template>
      <add-reference-single
         v-if="value.type === ContentBrickFieldType.List || value.type === ContentBrickFieldType.MultiSelectList"
         :value="getListValue(value)"
         entity="List"
         :apiEndpoint="getLists"
         newItemRoute="/list/new"
         itemDetailRoute="/list/detail"
         :disabled="readonly"
         required
         :sortDesc="true"
         @input="setListValue(value, $event)"
      ></add-reference-single>
      <add-reference-single
         v-if="value.type == ContentBrickFieldType.SubContentBrick"
         :value="getSubCbValue(value.subContentBrickCode)"
         entity="Sub Content Brick"
         :apiEndpoint="getSubContentBricks"
         newItemRoute="/subContentBricks/new"
         itemDetailRoute="/subContentBricks/detail"
         :disabled="readonly"
         required
         :sortDesc="true"
         @input="setSubCbValue(value, $event)"
      ></add-reference-single>
      <v-combobox
         v-if="value.type == ContentBrickFieldType.ComboBox"
         :value="getComboBoxOptions(value)"
         class="options-input"
         label="Options"
         item-text="text"
         multiple
         small-chips
         hide-no-data
         :readonly="true"
         @click="onComboboxButtonClick(value)"
      >
         <template #selection="{ item }">
            <v-chip small>
               {{ item.text }}
            </v-chip>
         </template>
         <template #append>
            <v-btn icon @click="onComboboxButtonClick(value)">
               <v-icon color="error">mdi-pencil</v-icon>
            </v-btn>
         </template>
      </v-combobox>
      <v-autocomplete
         v-if="value.type === ContentBrickFieldType.Boolean && !Util.isValueField(value)"
         v-model="value.booleanLayout"
         label="Layout"
         :items="fieldBooleanLayoutComboItems"
         item-value="value"
         :readonly="readonly"
      >
         <template #item="{ item }">
            {{ getDecoratorText(item) }}
         </template>
         <template #selection="{ item }">
            {{ getDecoratorText(item) }}
         </template>
      </v-autocomplete>
      <v-row v-if="requiresUnits(value)" align="center" dense>
         <v-col cols="2" class="pt-0 pl-2">
            <v-tooltip top>
               <template #activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                     <add-reference-single
                        :value="value.unitType"
                        entity="Unit Type"
                        :apiEndpoint="getUnitTypes"
                        newItemRoute="/unitTypes/new"
                        itemDetailRoute="/unitTypes/detail"
                        :disabled="readonly"
                        required
                        :headers="headerUnitTypes"
                        icon="mdi-numeric-1-box-multiple-outline"
                        :showOnlyButton="true"
                        @input="onUnitTypeChanged(value, $event)"
                     ></add-reference-single>
                  </div>
               </template>
               <span>Unit type</span>
            </v-tooltip>
         </v-col>
         <v-col cols="10">
            <div class="d-flex flex-row">
               <add-reference-single
                  v-if="value.unitType"
                  v-model="value.unit"
                  entity="Unit"
                  :apiEndpoint="() => getUnits(value)"
                  newItemRoute="/units/new"
                  itemDetailRoute="/units/detail"
                  :disabled="readonly"
                  required
                  :headers="headerUnits"
               ></add-reference-single>
               <div v-if="!Util.isValueField(value)" class="d-flex flex-column ml-2 unit-editable-switch">
                  <span class="text--secondary">Is Fixed</span>
                  <v-switch
                     v-model="value.isUnitFixed"
                     color="error"
                     class="pt-0 pb-0 mt-0"
                     hide-details
                     :disabled="readonly"
                  />
               </div>
            </div>
         </v-col>
      </v-row>
      <add-reference-single
         v-if="value.type === ContentBrickFieldType.DataTable"
         :value="getDataTableValue(value)"
         entity="Data Table"
         :apiEndpoint="getDataTables"
         newItemRoute="/dataTables/new"
         itemDetailRoute="/dataTables/detail"
         :disabled="readonly"
         :sortDesc="true"
         required
         @input="setDataTableValue(value, $event)"
      ></add-reference-single>
      <add-reference
         v-if="value.type === ContentBrickFieldType.DataTable"
         :value="getDataTableVisibleColumnsValue(value)"
         entity="Visible Columns"
         :apiEndpoint="getDataTableColumnsById"
         :disabled="readonly"
         :sortDesc="true"
         :minReferences="1"
         @input="setDataTableVisibleColumnsValue(value, $event)"
      ></add-reference>
   </div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import {
   ContentBrickTreeNodeTypeUtils as Util,
   ContentBrickFieldTypeUtils as FieldUtil,
   FieldDefinition,
} from "@models/shared/ContentBrickTreeNodeTypeUtils";
import {
   ContentBrickFieldType,
   UnitReference,
   ItemReference,
   ListItem,
   SubContentBrickDefinitionReference,
   DataTableColumnReference,
   DataTableColumnDefinition,
} from "@backend/api/pmToolApi";
import { ContentBrickFieldTypeDecorator } from "@models/shared/ContentBrickFieldTypeDecorator";
import AddReferenceSingle from "@components/Shared/add-reference-single.vue";
import AddReference from "@components/Shared/add-reference.vue";
import ViewItem from "@models/view/ViewItem";
import { BooleanFieldLayoutDecorator } from "@models/shared/BooleanFieldLayoutDecorator";
import { DocumentTypeDecorator } from "@models/shared/DocumentTypeDecorator";
import { ImageTypeDecorator } from "@models/shared/imageTypeDecorator";
import { VideoTypeDecorator } from "@models/shared/VideoTypeDecorator";
import { Guid } from "guid-typescript";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import EventBus from "@backend/EventBus";
import Events from "@models/shared/Events";

@Component({
   name: "ContentBrickFieldInline",
   components: {
      AddReferenceSingle,
      AddReference,
   },
})
export default class ContentBrickFieldInline extends ComponentBase {
   routeNames: string[] = [
      "boolean-field-layout-decorator",
      "document-type-decorator",
      "image-type-decorator",
      "video-type-decorator",
   ];

   @Prop({ required: true })
   value: FieldDefinition | DataTableColumnDefinition;

   @Prop({ default: false })
   readonly: boolean;

   @Prop({ default: false })
   loadingScbReferences: boolean;

   @Prop({ default: () => [] })
   subCbReferences: SubContentBrickDefinitionReference[];

   @Prop({
      default: () => () => {
         throw "invalid API endpoint";
      },
   })
   getUnits: (field: FieldDefinition) => Promise<UnitReference[]>;

   @Prop({
      default: () => () => {
         throw "invalid API endpoint";
      },
   })
   getUnitTypes: () => Promise<ItemReference[]>;

   @Prop({
      default: () => () => {
         throw "invalid API endpoint";
      },
   })
   getLists: () => Promise<ItemReference[]>;

   @Prop({
      default: () => () => {
         throw "invalid API endpoint";
      },
   })
   getDataTables: () => Promise<ItemReference[]>;

   @Prop({
      default: () => () => {
         throw "invalid API endpoint";
      },
   })
   getDataTableColumns: (id: string) => Promise<ItemReference[]>;

   @Prop({
      default: () => () => {
         throw "invalid API endpoint";
      },
   })
   getSubContentBricks: () => Promise<ItemReference[]>;

   Util: any = Util;
   ContentBrickFieldType = ContentBrickFieldType;

   async getDataTableColumnsById(): Promise<ItemReference[] | undefined> {
      if (!Util.isDataTableColumnGuard(this.value)) {
         throw new Error("Expected Data Table column type to get Data Table reference columns by ID");
      }
      const dataTable = this.getDataTableValue(this.value);

      if (!dataTable?.id) {
         return undefined;
      }
      return await this.getDataTableColumns(dataTable.id);
   }

   headerUnits: ViewItem[] = [{ text: "Name", value: "displayText" }];
   headerUnitTypes: ViewItem[] = [{ text: "Name", value: "displayText" }];

   get fieldBooleanLayoutComboItems() {
      return BooleanFieldLayoutDecorator.AllItems;
   }

   get documentTypeComboItems() {
      return DocumentTypeDecorator.AllItems;
   }

   getDocumentTypeDescription(item: DocumentTypeDecorator): string {
      return `${this.translateKey(item.translationKey)} ${item.extensions}`;
   }

   get imageTypeComboItems() {
      return ImageTypeDecorator.AllItems;
   }

   get videoTypeComboItems() {
      return VideoTypeDecorator.AllItems;
   }

   requiresUnits(field: FieldDefinition): boolean {
      return new ContentBrickFieldTypeDecorator(field.type).hasUnits;
   }

   onUnitTypeChanged(item: FieldDefinition, unitType: ItemReference) {
      if (item.unit && item.unit.unitType!.id !== unitType.id) {
         item.unit = undefined;
      }

      this.$set(item, "unitType", unitType);
   }

   mounted() {
      this.loadRouteTranslations(this.routeNames);
      EventBus.$on(Events.LanguageChanged, () => {
         this.loadRouteTranslations(this.routeNames);
      });
   }

   // -------- SCB -------------
   getSubCbValue(subContentBrickCode: string | undefined): ItemReference | undefined {
      if (!subContentBrickCode || this.loadingScbReferences) {
         return undefined;
      }
      var subCbReference = this.subCbReferences.find((scb) => scb.code === subContentBrickCode);
      return (
         subCbReference ??
         new ItemReference({
            id: Guid.EMPTY,
            code: subContentBrickCode,
            displayText: undefined,
            entityStatus: undefined,
            lastModified: undefined,
            created: undefined,
         })
      );
   }

   setSubCbValue(field: FieldDefinition, subCbReference: ItemReference | undefined) {
      if (field && subCbReference?.code) {
         this.$set(field, "subContentBrickCode", subCbReference.code);
         this.$emit("load-scb-requested", subCbReference.code);
      }
   }

   // ------- List -------
   getListValue(field: FieldDefinition): ItemReference | undefined {
      if (!field.listId || !this.isListField(field)) {
         return undefined;
      }

      var listReference = new ItemReference({
         id: field.listId,
         code: field.list?.code,
         displayText: field.list?.name,
         entityStatus: field.list?.entityStatus,
         lastModified: field.list?.lastModified,
         created: undefined,
      });

      return (
         listReference ??
         new ItemReference({
            id: field.listId,
            code: undefined,
            displayText: undefined,
            entityStatus: undefined,
            lastModified: undefined,
            created: undefined,
         })
      );
   }

   setListValue(field: FieldDefinition, listReference: ItemReference | undefined) {
      this.$emit("set-list-value", field, listReference);
   }

   isListField(field: FieldDefinition): boolean {
      return FieldUtil.isListField(field);
   }

   // ------- Data Table -------
   getDataTableValue(column: DataTableColumnDefinition): ItemReference | undefined {
      if (!column.dataTableId || column.type !== ContentBrickFieldType.DataTable) {
         return undefined;
      }

      return (
         column.dataTable ??
         new ItemReference({
            id: column.dataTableId,
         })
      );
   }

   setDataTableValue(column: DataTableColumnDefinition, dataTableReference: ItemReference | undefined) {
      column.dataTableId = dataTableReference?.id;
      column.dataTable = dataTableReference;
   }

   getDataTableVisibleColumnsValue(column: DataTableColumnDefinition): DataTableColumnReference[] | undefined {
      if (!column.visibleColumns || column.type !== ContentBrickFieldType.DataTable) {
         return undefined;
      }

      return column.visibleColumns;
   }

   setDataTableVisibleColumnsValue(column: DataTableColumnDefinition, columnReferences: ItemReference[] | undefined) {
      column.visibleColumns = columnReferences
         ? columnReferences.map((r) => new DataTableColumnReference(r))
         : undefined;
   }

   // ------- Combobox -------
   getComboBoxOptions(field: FieldDefinition): ListItem[] {
      return field.list?.items?.filter((co) => !co.disabled) ?? [];
   }

   onComboboxButtonClick(field: FieldDefinition): void {
      this.$emit("show-combobox-dialog", field);
   }
   // ------- validation -------
   documentRules = {
      documentMinCount: [
         (v) => ![null, undefined, ""].includes(v) || "Minimum count is required",
         (v) => v >= 0 || "Minimum count must be >= 0",
         this.validateDocumentsMinCount,
      ],
      documentMaxCount: [
         (v) => ![null, undefined, ""].includes(v) || "Maximum count is required",
         (v) => v >= 1 || "Maximum count must be >= 1",
         this.validateDocumentsMaxCount,
      ],
   };

   minControl;
   maxControl;
   validatingMin: boolean = false;
   validatingMax: boolean = false;

   setDocumentInputsToValidate(min, max) {
      this.minControl = min;
      this.maxControl = max;
   }

   validateDocumentsMinCount(v) {
      if (this.minControl === undefined) return true;
      let min: number = parseInt(v);
      let max: number = parseInt(this.maxControl.value);

      if (!this.validatingMax) {
         this.validatingMin = true;
         this.maxControl.validate();
         this.validatingMin = false;
      }

      this.minControl = undefined;
      this.maxControl = undefined;
      return min <= max || "Min should be less or equal max";
   }

   validateDocumentsMaxCount(v) {
      if (this.maxControl === undefined) return true;
      let min: number = parseInt(this.minControl.value);
      let max: number = parseInt(v);

      if (!this.validatingMin) {
         this.validatingMax = true;
         this.minControl.validate();
         this.validatingMax = false;
      }

      this.minControl = undefined;
      this.maxControl = undefined;
      return min <= max || "Max should be greater or equal min";
   }
}
</script>
