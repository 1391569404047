<template>
   <div>
      <v-autocomplete
         v-if="isSingleColumn"
         v-model="selectedValue"
         :items="listInternal.items"
         item-text="text"
         item-value="identifier"
         hide-details
         :readonly="readonly"
         :label="displayLabel"
         return-object
         :multiple="multiple"
      >
         <template #selection="{ item }">
            <single-column-list-value :value="item.text"></single-column-list-value>
         </template>
         <template #item="{ item, on, attrs }">
            <v-list-item v-bind="attrs" :class="{ 'list-item--hidden': item.disabled }" v-on="on">
               <single-column-list-value :value="item.text"></single-column-list-value>
            </v-list-item>
         </template>
      </v-autocomplete>
      <v-menu v-else v-model="menu" :close-on-content-click="false" offset-y>
         <template #activator="{ on, attrs }">
            <v-text-field
               v-if="listInternal"
               v-bind="attrs"
               :value="selectedValueDisplay"
               :label="displayLabel"
               :clearable="!readonly"
               readonly
               hide-details
               v-on="on"
               @click:clear="clearSelection"
            ></v-text-field>
         </template>
         <v-card :disabled="readonly">
            <v-list class="selection-menu">
               <v-list-item class="selection-menu-header" dense>
                  <v-row>
                     <v-col v-if="multiple">Selected</v-col>
                     <v-col v-for="header in listInternal.headers" :key="header.identifier">
                        <strong>{{ header.name }}</strong>
                     </v-col>
                  </v-row>
               </v-list-item>
               <v-divider></v-divider>
               <v-list-item
                  v-for="(item, index) in listInternal.items"
                  :key="index"
                  :class="{ 'list-item--hidden': item.disabled }"
                  dense
                  @click="toggleSelection(item)"
               >
                  <v-row>
                     <v-col v-if="multiple">
                        <v-checkbox :value="isItemSelected(item)" readonly></v-checkbox>
                     </v-col>
                     <v-col v-for="header in listInternal.headers" :key="header.identifier">
                        {{ item.columns[header.identifier] }}
                     </v-col>
                  </v-row>
               </v-list-item>
            </v-list>
         </v-card>
      </v-menu>
   </div>
</template>

<script lang="ts">
import { List, ListItem } from "@backend/api/pmToolApi";
import { Component, Prop, Vue } from "vue-property-decorator";
import SingleColumnListValue from "@components/Shared/single-column-list-value.vue";

@Component({
   name: "MultiColumnListSelect",
   components: { SingleColumnListValue },
})
export default class MultiColumnListSelect extends Vue {
   menu: boolean = false;

   listInternal: List = new List();

   @Prop({ required: true })
   list!: List;

   @Prop({ default: undefined })
   label?: string;

   // TODO, consider treating single & multicolumn lists the same and always use ListItem
   @Prop({ required: true, default: undefined })
   value!: ListItem | (string | ListItem)[] | string | undefined | null;

   @Prop({ default: false })
   multiple!: boolean;

   @Prop({ default: false })
   readonly: boolean;

   get isSingleColumn(): boolean {
      return this.list.isMultiColumn === false;
   }

   get displayLabel(): string {
      return this.label ?? this.list.name ?? "Unknown list";
   }

   get selectedValue(): ListItem | ListItem[] | undefined | null {
      const wrap = (value: string | ListItem | undefined | null) =>
         typeof value === "string" ? this.list.items?.find((x) => x.identifier === value) : value;
      if (this.multiple) {
         return (Array.isArray(this.value) ? this.value : [this.value]).map(wrap).filter((x) => x != undefined);
      }
      return wrap(this.value as undefined | ListItem | string);
   }

   set selectedValue(value: ListItem | ListItem[] | undefined | null) {
      this.updateValue(value);
   }

   get selectedValueDisplay(): string {
      let result: string = "";

      if (!this.selectedValue) {
         return "";
      }

      if (this.multiple && Array.isArray(this.selectedValue)) {
         result = this.selectedValue
            .map((item) => this.list.headers?.map((header) => item.columns[header.identifier]).join(" "))
            .join(", ");
      } else if (!this.multiple && this.selectedValue) {
         result =
            this.list.headers?.map((header) => (this.selectedValue as ListItem).columns[header.identifier]).join(" ") ??
            "";
      }
      return result;
   }

   isItemSelected(item: ListItem): boolean {
      if (this.multiple && Array.isArray(this.selectedValue)) {
         return this.selectedValue.some((selected) => this.compareItems(selected, item));
      }
      return false;
   }

   compareItems(item1: ListItem, item2: ListItem): boolean {
      if (!this.list.headers) {
         return false;
      }

      for (const header of this.list.headers) {
         if (item1.columns[header.identifier] !== item2.columns[header.identifier]) {
            return false;
         }
      }

      return true;
   }

   updateValue(value: ListItem | ListItem[]) {
      if (this.isSingleColumn) {
         const unwrap = (x: ListItem) => x.identifier;
         const result = Array.isArray(value) ? value.filter((x) => x != null).map(unwrap) : unwrap(value);
         this.$emit("input", result);
         return;
      }
      this.$emit("input", value);
   }

   toggleSelection(item: ListItem) {
      if (this.multiple) {
         if (Array.isArray(this.selectedValue)) {
            const index = this.selectedValue.findIndex((selected) => this.compareItems(selected, item));
            if (index >= 0) {
               this.selectedValue.splice(index, 1);
            } else {
               this.selectedValue.push(item);
            }
            this.updateValue([...this.selectedValue]);
         } else {
            this.updateValue([item]);
         }
      } else {
         this.selectedValue = item;
         this.menu = false;
      }
   }

   clearSelection() {
      if (this.multiple) {
         this.selectedValue = [];
      } else {
         this.selectedValue = undefined;
      }
   }

   mounted() {
      this.listInternal.headers = this.list.headers?.map((header) => ({
         name: header.name,
         identifier: header.identifier,
      }));

      this.listInternal.items =
         this.list.items?.map((item) => ({
            columns: { ...item.columns },
            identifier: item.identifier,
            text: item.text,
            disabled: item.disabled,
         })) ?? [];

      this.$forceUpdate();
   }
}
</script>

<style scoped>
.selection-menu {
   max-height: 300px;
   overflow-y: auto;
   padding: 0;
}

.selection-menu-header {
   position: sticky;
   top: 0;
   z-index: 1;
   background: white;
   margin: 0;
}
</style>
