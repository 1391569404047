<template>
   <div>
      <v-menu v-model="isOpen" class="notification-list" offset-y nudge-width="200">
         <!-- unclear intent -->
         <!-- eslint-disable-next-line vue/no-useless-template-attributes -->
         <template #activator="{ on }" :offset-x="true" :close-on-content-click="false" nudge-width="500">
            <v-badge color="red lighten-1" :content="notificationCount" overlap bottom :value="notificationCount > 0">
               <v-btn icon elevation="2" class="nav-menu-icon" v-on="on">
                  <v-icon>mdi-forum</v-icon>
               </v-btn>
            </v-badge>
         </template>
         <v-card>
            <div v-if="notificationCount > 0" class="header-row" justify>New Messages: {{ notificationCount }}</div>

            <v-divider v-if="notificationCount > 0" class="my-0 grey lighten-1" />
            <v-list>
               <v-list-item
                  v-for="item in notifications"
                  :key="item.id"
                  link
                  tag="span"
                  @click="notificationClick(item)"
               >
                  <div>
                     <v-icon>{{ item.icon }}</v-icon>
                  </div>
                  <div class="chat-name">
                     {{ item.name }}
                  </div>
                  <div class="chat-text">
                     {{ item.text }}
                  </div>
               </v-list-item>
               <v-spacer></v-spacer>
            </v-list>

            <div>
               <v-btn x-small tile text class="deep-orange lighten-5" block elevation="0" to="/messenger">
                  Messenger
               </v-btn>
            </div>
         </v-card>
      </v-menu>
   </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component({
   name: "ChatNotifications",
   components: {},
})
export default class ChatNotifications extends Vue {
   isOpen: boolean = false;
   notificationCount: number = 3;
   notifications: object[] = [
      {
         id: 0,
         name: "Tester Test",
         text: "Hello, Testing test",
         icon: "mdi-account-outline",
         taskId: 2,
         isActive: true,
      },
      {
         id: 1,
         name: "Libor Fridrich",
         text: "Could you check PBB00001 ?",
         icon: "mdi-account-outline",
         taskId: 1,
         isActive: true,
      },
      {
         id: 2,
         name: "Pavel Sajnar",
         text: "How are you ?",
         icon: "mdi-account-outline",
         taskId: 3,
         isActive: false,
      },
   ];
}
</script>

<style scoped>
.notification-list {
   z-index: 15;
}

.chat-name {
   font-size: 13px;
   margin-left: 0.2rem;
}

.chat-text {
   font-size: 11px;
   margin-left: 0.6rem;
   position: relative;
}

.header-row {
   font-size: 13px;
   height: 1.8rem;
   padding-left: 2rem;
   padding-top: 5px;
}

.messenger-btn {
   font-size: 12px;
}
</style>
