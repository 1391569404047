<template>
   <v-card class="entity-detail-card" :color="color">
      <v-card-title v-if="entity" class="pt-2" :class="isRenderedExpanded ? 'pb-0' : 'pb-2'">
         <slot name="title">
            <v-col class="pl-0 pt-0 pb-0" :class="titleLayoutClasses">
               <slot name="title-text">
                  {{ title }}
               </slot>
            </v-col>
            <v-spacer></v-spacer>
            <template v-if="!isRenderedExpanded">
               <span v-if="entity.code" class="text--secondary mr-3">{{ entity.code }}</span>
               <v-autocomplete
                  v-if="entity.availableVersions"
                  :value="entity.id"
                  :items="entity.availableVersions"
                  item-value="id"
                  item-text="code"
                  hide-details
                  return-object
                  :readonly="!canChangeVersion"
                  class="entity-version-select-collapsed mr-3 mt-0"
                  dense
                  @input="onVersionChanged"
               />
            </template>
            <v-btn
               v-show="entityDraftId"
               class="draft-btn mr-3 lighten-3"
               x-small
               depressed
               outlined
               @click="onDraftClick($event, entity.version.draftId)"
            >
               <v-icon small>mdi-link</v-icon>
               &nbsp; Draft
            </v-btn>
            <v-btn
               v-if="isDeleteButtonShown"
               class="mr-3"
               color="red"
               elevation="2"
               icon
               ui-test-data="delete-btn"
               @click="showDeleteConfirmationDialog()"
            >
               <v-icon>mdi-delete</v-icon>
            </v-btn>
            <entity-status-select
               v-if="hasStatus"
               v-model="entity.entityStatus"
               :isFromAdministration="isFromAdministration"
               :isGetDraftOnly="isGetDraftOnly"
               :readonly="!canChangeStatus"
            ></entity-status-select>
            <detail-header-expand-button v-model="isExpandedInternal" class="ml-3" />
         </slot>
      </v-card-title>
      <v-card-text v-if="entity && isRenderedExpanded" class="pb-2">
         <v-container fluid>
            <v-row v-if="isMetaShown" class="entity-detail-card-text" justify="center" align="center" :dense="dense">
               <v-col v-if="entity.code">
                  <label-value :label="translateKey('detailHeader.codeLabel')" :value="entity.code" />
               </v-col>
               <v-col v-if="entity.availableVersions">
                  <v-autocomplete
                     :value="entity.id"
                     :items="entity.availableVersions"
                     item-value="id"
                     item-text="code"
                     label="Version"
                     hide-details
                     return-object
                     :readonly="!canChangeVersion"
                     @input="onVersionChanged"
                  />
               </v-col>
               <v-col v-if="entity && entity.created">
                  <label-value :label="translateKey('detailHeader.createdLabel')" :value="createdLabel" />
               </v-col>
               <v-col v-if="entity && entity.lastModified">
                  <label-value :label="translateKey('detailHeader.modifiedLabel')" :value="modifiedLabel" />
               </v-col>
            </v-row>
         </v-container>
         <slot></slot>
      </v-card-text>
      <confirm-delete-dialog
         :showDialog="isDeleteConfirmationDialogShown"
         itemType="draft"
         @cancelDeleteItem="canceledRemove"
         @confirmDeleteItem="confirmedRemove"
      ></confirm-delete-dialog>
   </v-card>
</template>
<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import Status from "@components/Shared/status.vue";
import LabelValue from "@components/Shared/label-value.vue";
import EntityStatusSelect from "@components/Shared/entity-status-select.vue";
import ConfirmDeleteDialog from "@components/Shared/confirm-delete-dialog.vue";
import { EntityStatusDecorator } from "@models/shared/EntityStatusDecorator";
import { EntityStatus, IItemReference } from "@backend/api/pmToolApi";
import DetailHeaderExpandButton from "@components/Shared/detail-header-expand-button.vue";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import EventBus from "@backend/EventBus";
import Events from "@models/shared/Events";

@Component({
   name: "DetailHeader",
   components: {
      Status,
      LabelValue,
      EntityStatusSelect,
      ConfirmDeleteDialog,
      DetailHeaderExpandButton,
   },
})
export default class DetailHeader extends ComponentBase {
   @Prop({ default: false })
   isNew: boolean;

   @Prop({ default: true })
   isFromAdministration: boolean;

   @Prop({ default: false })
   isGetDraftOnly: boolean;

   @Prop({ default: "Missing title" })
   title: string;

   @Prop({ default: undefined })
   entity: object;

   @Prop({ default: undefined })
   deleteFunc?: () => Promise<void>;

   @Prop({ default: undefined })
   originalStatus?: EntityStatus;

   // TODO: can be default?
   @Prop({ default: true })
   dense: boolean;

   @Prop({ default: true })
   showStatus: boolean;

   @Prop({ default: true })
   canChangeVersion: boolean;

   @Prop({ default: true })
   canChangeStatus: boolean;

   @Prop({ default: true })
   canDelete: boolean;

   @Prop({ default: undefined })
   color: string;

   @Prop({ required: false })
   currentTab: string;

   @Prop({ default: null })
   isExpanded: boolean | null;

   get entityDraftId(): string | undefined {
      return this.entity.version?.draftId;
   }

   get createdLabel(): string {
      var userName = this.entity?.created?.user?.displayText ?? "Unknown user";
      var createdDate = this.entity?.created?.timestamp.format("l LT") ?? "Unknown date";
      return this.getUserWithDateLabel(userName, createdDate);
   }

   get modifiedLabel(): string {
      var userName = this.entity?.lastModified?.user?.displayText ?? "Unknown user";
      var modifiedDate = this.entity?.lastModified?.timestamp.format("l LT") ?? "Unknown date";
      return this.getUserWithDateLabel(userName, modifiedDate);
   }

   get isMetaShown(): boolean {
      return !this.isNew;
   }

   get isDeleteButtonShown(): boolean {
      return (
         !this.isNew &&
         !!this.deleteFunc &&
         this.originalStatus !== undefined &&
         this.originalStatus === EntityStatus.Draft &&
         this.canDelete
      );
   }

   get hasStatus(): boolean {
      return this.showStatus && this.entity?.entityStatus !== undefined;
   }

   get hasEntityDraft(): boolean {
      return !!this.entityDraftId;
   }

   get hasVersions(): boolean {
      return !!this.entity["availableVersions"];
   }

   get allStates(): EntityStatusDecorator[] {
      return EntityStatusDecorator.AllItems;
   }

   get entityStatusColor(): string {
      return new EntityStatusDecorator(this.entity.entityStatus).color;
   }

   getUserWithDateLabel(userName: string, date: string): string {
      return `${userName} - ${date}`;
   }

   onDraftClick(event, id: string) {
      if (this.$router.currentRoute.params.id != id) {
         if (!event.ctrlKey) {
            void this.$router.push({
               params: { ...this.$router.currentRoute.params, id: id, restoreTab: this.currentTab },
            });
         } else {
            let routeData = this.$router.resolve({
               name: this.$router.currentRoute.name,
               params: { id: id },
            });
            window.open(routeData.href, "_blank");
         }
      }
   }

   onVersionChanged(version: IItemReference): void {
      if (this.$router.currentRoute.params.id != version.id) {
         void this.$router.replace({
            params: { ...this.$router.currentRoute.params, id: version.id, restoreTab: this.currentTab },
         });
      }
   }

   //-------------- Expansion ---------------
   get isRenderedExpanded(): boolean {
      return (
         (this.isExpanded !== null && this.isExpanded) || (this.isExpandedInternal !== null && this.isExpandedInternal)
      );
   }
   isExpandedInternal: boolean | null = null;

   get titleLayoutClasses(): string {
      if (this.isRenderedExpanded) {
         // expanded full header
         if (this.hasEntityDraft) {
            // draft button larger -> smaller md-5
            return "col-12 col-md-5 col-lg-7 col-xl-7";
         } else {
            // delete button smaller -> bigger md-6
            return "col-12 col-md-6 col-lg-7 col-xl-7";
         }
      } else {
         // collapsed slim header
         if (this.hasVersions) {
            if (this.hasEntityDraft) {
               // draft button larger -> smaller lg-4
               return "col-12 col-md-12 col-lg-4 col-xl-7";
            } else {
               // delete button smaller -> bigger lg-5
               return "col-12 col-md-12 col-lg-5 col-xl-7";
            }
         } else {
            // no versions -> no version select, draft button -> smaller md-5
            return "col-12 col-md-5 col-lg-5 col-xl-7";
         }
      }
   }

   //-------- Confirm delete dialog -----------
   isDeleteConfirmationDialogShown: boolean = false;

   showDeleteConfirmationDialog() {
      this.isDeleteConfirmationDialogShown = true;
   }

   hideDeleteConfirmationDialog() {
      this.isDeleteConfirmationDialogShown = false;
   }

   canceledRemove(): void {
      this.hideDeleteConfirmationDialog();
   }

   confirmedRemove(): void {
      if (this.deleteFunc) {
         void this.deleteFunc();
      }
      this.hideDeleteConfirmationDialog();
   }

   mounted() {
      this.loadRouteTranslations("detail-header");
      EventBus.$on(Events.LanguageChanged, () => this.loadRouteTranslations("detail-header"));
   }
}
</script>

<style scoped>
.entity-detail-card {
   z-index: 10;
}

.entity-version-select-collapsed {
   width: 90px;
   flex: none;
   font-weight: normal;
}
</style>
