import { render, staticRenderFns } from "./nav-menu.vue?vue&type=template&id=d8f70692&scoped=true"
import script from "./nav-menu.vue?vue&type=script&lang=ts"
export * from "./nav-menu.vue?vue&type=script&lang=ts"
import style0 from "./nav-menu.vue?vue&type=style&index=0&id=d8f70692&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8f70692",
  null
  
)

export default component.exports