<template>
   <v-dialog v-if="showDialog" v-model="showDialog" width="75%" persistent :loading="loading">
      <v-card>
         <add-reference-dialog-header
            :title="title"
            :newItemRoute="newItemRoute"
            :clickReload="clickReloadFunc"
            :clickClose="clickClose"
         ></add-reference-dialog-header>
         <v-card-text v-if="field">
            <v-form ref="detailForm">
               <v-row col>
                  <v-col cols="3">
                     <v-text-field
                        ref="nameField"
                        v-model="fieldName"
                        :label="fieldNameLabel(field.type)"
                        :rules="rules.name"
                        autofocus
                        @blur="onContentBrickFieldNameBlur(field)"
                     ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                     <v-text-field
                        ref="identifierField"
                        v-model="fieldIdentifier"
                        label="Identifier"
                        :rules="rules.identifier"
                        append-icon="mdi-refresh"
                        @click:append="regenerateIdentifier"
                     ></v-text-field>
                  </v-col>
                  <v-col v-if="!isDataTableColumnMode" cols="3">
                     <add-reference-single
                        v-model="fieldTechnicalMeasure"
                        entity="Technical Measure"
                        :apiEndpoint="getTechnicalMeasures"
                        newItemRoute="/technicalMeasure/new"
                        itemDetailRoute="/technicalMeasure/detail"
                        :rules="rules.technicalMeasure"
                        :headers="tmHeaders"
                     >
                        <template #autocomplete-item="{ item }">
                           <div>
                              {{ item.displayText }}
                              <div class="subtitle">{{ item.subtitle }}</div>
                           </div>
                        </template>
                     </add-reference-single>
                  </v-col>
                  <v-col cols="3">
                     <v-autocomplete
                        v-model="field.type"
                        :items="fieldTypes"
                        item-value="value"
                        :rules="rules.type"
                        :item-text="(item) => getDecoratorText(item)"
                        label="Data Type"
                        :readonly="isTechnicalMeasure"
                     />
                  </v-col>
                  <v-col cols="3">
                     <add-reference-single
                        v-if="isRegularExpressionEnabled()"
                        v-model="fieldRegularExpression"
                        entity="Formatting"
                        :apiEndpoint="() => getRegularExpressions(field)"
                        newItemRoute="/regularExpressions/new"
                        itemDetailRoute="/regularExpressions/detail"
                        required
                        :disabled="isTechnicalMeasure"
                     ></add-reference-single>
                  </v-col>
                  <v-col cols="3">
                     <add-reference-single
                        v-if="hasUnits"
                        v-model="fieldUnitType"
                        entity="Unit Type"
                        :apiEndpoint="getUnitTypes"
                        newItemRoute="/unitTypes/new"
                        itemDetailRoute="/unitTypes/detail"
                        required
                        :rules="rules.unitType"
                        :headers="headerUnitTypes"
                        :disabled="isTechnicalMeasure"
                     ></add-reference-single>
                  </v-col>
                  <v-col cols="3">
                     <add-reference-single
                        v-if="hasUnits && fieldUnitType"
                        v-model="fieldUnit"
                        entity="Unit"
                        :apiEndpoint="() => getUnits(field)"
                        newItemRoute="/units/new"
                        itemDetailRoute="/units/detail"
                        required
                        :rules="rules.unit"
                        :headers="headerUnits"
                        :disabled="isTechnicalMeasure"
                     ></add-reference-single>
                  </v-col>
                  <v-col v-if="isFieldMode" cols="3">
                     <v-switch v-model="field.isMandatory" title="Is Mandatory" color="error" label="Is Mandatory" />
                  </v-col>
                  <template v-if="isDataTableVisible">
                     <v-col cols="3">
                        <add-reference-single
                           v-model="dataTable"
                           entity="Data Table"
                           :apiEndpoint="getDataTables"
                           newItemRoute="/dataTables/new"
                           itemDetailRoute="/dataTables/detail"
                           :disabled="false"
                           required
                        ></add-reference-single>
                     </v-col>
                     <v-col v-if="dataTable && dataTable.id" cols="3">
                        <add-reference
                           v-model="dataTableColumns"
                           entity="Visible Columns"
                           :apiEndpoint="getDataTableColumnsById"
                           :disabled="false"
                           :minReferences="1"
                        ></add-reference>
                     </v-col>
                  </template>
               </v-row>
               <v-expand-transition>
                  <div v-if="field.type === contentBrickFieldTypeCalculated">
                     <field-calculation
                        v-model="field.fieldCalculation"
                        :title="''"
                        :fieldReferences="calculatableFields"
                        :readonly="false"
                     ></field-calculation>
                  </div>
               </v-expand-transition>
               <v-expand-transition>
                  <v-row v-if="field.type === contentBrickFieldTypeDocument && isFieldMode">
                     <v-col cols="6">
                        <v-autocomplete
                           v-model="field.documentType"
                           :items="documentTypeComboItems"
                           :rules="rules.documentType"
                           item-value="value"
                           label="Document Type"
                           :item-text="(item) => getDecoratorText(item)"
                        />
                     </v-col>
                     <v-col cols="3">
                        <v-text-field
                           ref="txtMinCount"
                           v-model="field.documentMinCount"
                           label="Minimum count"
                           min="0"
                           :max="field.documentMaxCount"
                           type="number"
                           :rules="rules.documentMinCount"
                        ></v-text-field>
                     </v-col>
                     <v-col cols="3">
                        <v-text-field
                           ref="txtMaxCount"
                           v-model="field.documentMaxCount"
                           label="Maximum count"
                           :min="field.documentMinCount"
                           type="number"
                           :rules="rules.documentMaxCount"
                        ></v-text-field>
                     </v-col>
                  </v-row>
               </v-expand-transition>
               <v-expand-transition>
                  <v-row v-if="field.type === contentBrickFieldTypeImage && isFieldMode">
                     <v-col cols="6">
                        <v-autocomplete
                           v-model="field.imageType"
                           :items="imageTypeComboItems"
                           :rules="rules.imageType"
                           :item-text="(item) => getDecoratorText(item)"
                           item-value="value"
                           label="Image Types"
                        />
                     </v-col>
                     <v-col cols="3">
                        <v-text-field
                           ref="txtMinCount"
                           v-model="field.documentMinCount"
                           label="Minimum count"
                           min="0"
                           :max="field.documentMaxCount"
                           type="number"
                           :rules="rules.documentMinCount"
                        ></v-text-field>
                     </v-col>
                     <v-col cols="3">
                        <v-text-field
                           ref="txtMaxCount"
                           v-model="field.documentMaxCount"
                           label="Maximum count"
                           :min="field.documentMinCount"
                           type="number"
                           :rules="rules.documentMaxCount"
                        ></v-text-field>
                     </v-col>
                  </v-row>
               </v-expand-transition>
               <v-expand-transition>
                  <v-row v-if="field.type === contentBrickFieldTypeVideo && isFieldMode">
                     <v-col cols="6">
                        <v-autocomplete
                           v-model="field.videoType"
                           :items="videoTypeComboItems"
                           :rules="rules.videoType"
                           :item-text="(item) => getDecoratorText(item)"
                           item-value="value"
                           label="Video Type"
                        />
                     </v-col>
                     <v-col cols="3">
                        <v-text-field
                           ref="txtMinCount"
                           v-model="field.documentMinCount"
                           label="Minimum count"
                           min="0"
                           :max="field.documentMaxCount"
                           type="number"
                           :rules="rules.documentMinCount"
                        ></v-text-field>
                     </v-col>
                     <v-col cols="3">
                        <v-text-field
                           ref="txtMaxCount"
                           v-model="field.documentMaxCount"
                           label="Maximum count"
                           :min="field.documentMinCount"
                           type="number"
                           :rules="rules.documentMaxCount"
                        ></v-text-field>
                     </v-col>
                  </v-row>
               </v-expand-transition>
               <v-expand-transition>
                  <v-row
                     v-if="field.type === contentBrickFieldTypeList || field.type === contentBrickFieldTypeMultiList"
                  >
                     <v-col cols="6">
                        <add-reference-single
                           v-model="fieldList"
                           entity="List"
                           :apiEndpoint="getLists"
                           newItemRoute="/list/new"
                           itemDetailRoute="/list/detail"
                           required
                           :rules="rules.listType"
                           :sortDesc="true"
                        ></add-reference-single>
                     </v-col>
                  </v-row>
               </v-expand-transition>
               <v-expand-transition>
                  <v-row v-if="field.type === contentBrickFieldTypeScb">
                     <v-col cols="6">
                        <add-reference-single
                           v-model="fieldSubContentBrick"
                           entity="Sub Content Brick"
                           :apiEndpoint="getSubContentBricks"
                           newItemRoute="/subContentBricks/new"
                           itemDetailRoute="/subContentBricks/detail"
                           required
                           :rules="rules.subContentBrick"
                           :sortDesc="true"
                        ></add-reference-single>
                     </v-col>
                  </v-row>
               </v-expand-transition>
               <v-expand-transition>
                  <v-row v-if="field.type === contentBrickFieldTypeBoolean">
                     <v-col cols="3">
                        <v-autocomplete
                           v-model="field.booleanLayout"
                           label="Layout"
                           :items="booleanLayouts"
                           :item-text="(item) => getDecoratorText(item)"
                           item-value="value"
                           :rules="rules.booleanLayout"
                        ></v-autocomplete>
                     </v-col>
                  </v-row>
               </v-expand-transition>
            </v-form>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="error" text :disabled="loading" @click="addContentBrickField">Add</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, Watch } from "vue-property-decorator";
import AddReferenceDialogHeader from "@components/Shared/add-reference-dialog-header.vue";
import AddReferenceSingle from "@components/Shared/add-reference-single.vue";
import AddReference from "@components/Shared/add-reference.vue";
import FieldCalculation from "@components/ContentBricks/Shared/field-calculation.vue";
import { Guid } from "guid-typescript";
import { DocumentTypeDecorator } from "@models/shared/DocumentTypeDecorator";
import { ImageTypeDecorator } from "@models/shared/imageTypeDecorator";
import { ContentBrickFieldTypeDecorator } from "@models/shared/ContentBrickFieldTypeDecorator";
import { BooleanFieldLayoutDecorator } from "@models/shared/BooleanFieldLayoutDecorator";
import {
   IContentBrickFieldDefinition,
   ContentBrickFieldDefinition,
   FieldCalculationDefinition,
   ContentBrickFieldType,
   IItemReference,
   ItemReference,
   UnitReference,
   DesignGuidelineValueFieldDefinition,
   DesignGuidelineFieldEvaluation,
   List,
   GateCondition,
   TechnicalMeasureExtendedReference,
   DataTableColumnDefinition,
   DataTableColumnReference,
} from "@backend/api/pmToolApi";
import ViewItem from "@models/view/ViewItem";
import DataModelUtils from "@utils/DataModelUtils";
import ContentBrickUtils, { IFieldCalculationReference } from "@utils/ContentBrickUtils";
import ValidationRules from "@models/shared/ValidationRules";
import Events from "@models/shared/Events";
import EventBus from "@backend/EventBus";
import RegularExpressionUtils from "@utils/RegularExpressionUtils";
import { VideoTypeDecorator } from "@models/shared/VideoTypeDecorator";
import AttachmentUtils from "@utils/AttachmentUtils";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import { PagingStore } from "@utils/Paging";

export enum ContentBrickAddFieldDialogMode {
   Field = 1,
   ValueField = 2,
   DataTableColumn = 3,
}

@Component({
   name: "ContentBrickAddFieldDialog",
   components: {
      AddReferenceDialogHeader,
      AddReferenceSingle,
      AddReference,
      FieldCalculation,
   },
})
export default class ContentBrickAddFieldDialog extends ComponentBase {
   loading: boolean = false;
   field: IContentBrickFieldDefinition = DesignGuidelineValueFieldDefinition.fromJS({
      id: Guid.create().toString(), // make temprorary guid for conditions
   });

   contentBrickFieldTypeCalculated: ContentBrickFieldType = ContentBrickFieldType.Calculated;
   contentBrickFieldTypeDocument: ContentBrickFieldType = ContentBrickFieldType.Document;
   contentBrickFieldTypeList: ContentBrickFieldType = ContentBrickFieldType.List;
   contentBrickFieldTypeImage: ContentBrickFieldType = ContentBrickFieldType.Image;
   contentBrickFieldTypeVideo: ContentBrickFieldType = ContentBrickFieldType.Video;
   contentBrickFieldTypeScb: ContentBrickFieldType = ContentBrickFieldType.SubContentBrick;
   contentBrickFieldTypeBoolean: ContentBrickFieldType = ContentBrickFieldType.Boolean;
   contentBrickFieldTypeMultiList: ContentBrickFieldType = ContentBrickFieldType.MultiSelectList;

   routeNames: string[] = [
      "boolean-field-layout-decorator",
      "content-brick-field-type-decorator",
      "document-type-decorator",
      "image-type-decorator",
      "video-type-decorator",
   ];

   @Prop({ default: false })
   showDialog: boolean;

   @Prop({ default: () => [] })
   units: IItemReference[];

   @Prop({ default: false })
   loadingDependencies: boolean;

   @Prop({ default: false })
   isUnitFixedDefaultValue: boolean;

   async getDataTableColumnsById(): Promise<ItemReference[] | undefined> {
      if (!this.dataTable?.id) {
         return undefined;
      }
      return await this.getDataTableColumns(this.dataTable.id);
   }

   @Watch("showDialog", { deep: true, immediate: true })
   onValueChanged(): void {
      if (this.showDialog) {
         // after opening reset data
         if (this.isValueFieldMode) {
            this.field = DesignGuidelineValueFieldDefinition.fromJS({
               id: Guid.create().toString(), // make temprorary guid for conditions
               evaluationType: DesignGuidelineFieldEvaluation.Disabled,
               operator: "AND",
               isUnitFixed: this.isUnitFixedDefaultValue,
               visibilityCondition: new GateCondition({
                  description: undefined,
                  predicate: undefined,
                  queries: [],
               }),
            });
         } else if (this.isFieldMode) {
            this.field = ContentBrickFieldDefinition.fromJS({
               id: Guid.create().toString(), // make temprorary guid for conditions
               isUnitFixed: this.isUnitFixedDefaultValue,
               visibilityCondition: new GateCondition({
                  description: undefined,
                  predicate: undefined,
                  queries: [],
               }),
            });
         } else if (this.isDataTableColumnMode) {
            this.field = new DataTableColumnDefinition({
               id: Guid.create().toString(), // make temprorary guid for conditions
               isUnitFixed: this.isUnitFixedDefaultValue,
               isUnique: false,
               isDeleted: false,
               allowMultipleValue: false,
               dataTableId: undefined,
               dataTable: undefined,
               defaultValue: undefined,
               visibleColumns: undefined,
            });
         }
         (this.field as any)._isUnsavedNewField = true;
         this.selectedRegulaExpression = undefined;
         this.selectedDataTable = undefined;
      }
   }

   @Prop({
      default: () => () => {
         throw "invalid API endpoint";
      },
   })
   getUnits: (field: IContentBrickFieldDefinition) => Promise<UnitReference[]>;

   @Prop({
      default: () => () => {
         throw "invalid API endpoint";
      },
   })
   getUnitTypes: () => Promise<ItemReference[]>;

   @Prop({
      default: () => () => {
         throw "invalid API endpoint";
      },
   })
   getLists: () => Promise<ItemReference[]>;

   @Prop({
      default: () => () => {
         throw "invalid API endpoint";
      },
   })
   getSubContentBricks: () => Promise<ItemReference[]>;

   @Prop({
      default: () => () => {
         throw "invalid API endpoint";
      },
   })
   getRegularExpressions: (field: IContentBrickFieldDefinition) => Promise<ItemReference[]>;

   @Prop({
      default: () => () => {
         throw "invalid API endpoint";
      },
   })
   loadRegularExpressionsByIds: (ids: string[]) => Promise<ItemReference[]>;

   @Prop({
      default: () => () => {
         throw "invalid API endpoint";
      },
   })
   getDataTables: () => Promise<ItemReference[]>;

   @Prop({
      default: () => () => {
         throw "invalid API endpoint";
      },
   })
   getDataTableColumns: (id: string) => Promise<ItemReference[]>;

   @Prop({
      default: () => () => {
         throw "invalid API endpoint";
      },
   })
   getTechnicalMeasures: (store: PagingStore) => Promise<TechnicalMeasureExtendedReference[]>;

   @Prop({ default: () => [] })
   headerUnits: ViewItem[];

   @Prop({ default: () => [] })
   headerUnitTypes: ViewItem[];

   @Prop({ default: () => [] })
   fields: ContentBrickFieldDefinition[];

   @Prop({ default: () => [] })
   metadataFields: ContentBrickFieldDefinition[];

   @Prop({ required: true })
   fieldTypes: ContentBrickFieldTypeDecorator[];

   @Prop({ default: false })
   allowTechnicalMeasures: boolean;

   @Prop({ required: true })
   booleanLayouts: BooleanFieldLayoutDecorator[];

   @Prop({ required: true })
   mode: ContentBrickAddFieldDialogMode;

   get isValueFieldMode(): boolean {
      return this.mode === ContentBrickAddFieldDialogMode.ValueField;
   }

   get isFieldMode(): boolean {
      return this.mode === ContentBrickAddFieldDialogMode.Field;
   }

   get isDataTableColumnMode(): boolean {
      return this.mode === ContentBrickAddFieldDialogMode.DataTableColumn;
   }

   tmHeaders: ViewItem[] = [
      {
         text: "Code",
         value: "code",
         class: "pmtool-table-header-fixed-sm",
         translationKey: "addReferenceSelectionDialog.codeHeader",
      },
      { text: "Name", value: "displayText", translationKey: "addReferenceSelectionDialog.nameHeader" },
      { text: "Subtitle", value: "subtitle" },
   ];

   get isTechnicalMeasure() {
      return this.field?.technicalMeasure != null;
   }

   get fieldTechnicalMeasure() {
      return this.field.technicalMeasure;
   }

   set fieldTechnicalMeasure(value: TechnicalMeasureExtendedReference | undefined) {
      this.field.technicalMeasure = value;
      if (value != undefined) {
         this.field.type = value.type;
         this.fieldRegularExpression = value.regularExpression;
         this.fieldUnitType = value.unitType;
         this.fieldUnit = value.unit;
      }
   }

   get title(): string {
      return !this.isDataTableColumnMode ? "Add Field" : "Add Column";
   }

   get fieldName(): string | undefined {
      return this.field?.name;
   }

   set fieldName(value: string | undefined) {
      if (this.field) {
         this.$set(this.field, "name", value?.trim());
      }
   }

   get fieldIdentifier(): string | undefined {
      return this.field?.identifier;
   }

   set fieldIdentifier(value: string | undefined) {
      if (this.field) {
         this.$set(this.field, "identifier", value?.trim());
      }
   }

   get isListFieldTypeSelected(): boolean {
      return this.field.type === ContentBrickFieldType.List;
   }

   get isCalculatedFieldTypeSelected(): boolean {
      return this.field.type === ContentBrickFieldType.Calculated;
   }

   get isUpdatableFieldTypeSelected(): boolean {
      return this.field.type === ContentBrickFieldType.List || this.field.type === ContentBrickFieldType.Calculated;
   }

   get hasUnits(): boolean {
      return new ContentBrickFieldTypeDecorator(this.field.type).hasUnits;
   }

   get documentTypeComboItems() {
      return DocumentTypeDecorator.AllItems;
   }

   get imageTypeComboItems() {
      return ImageTypeDecorator.AllItems;
   }

   get videoTypeComboItems() {
      return VideoTypeDecorator.AllItems;
   }

   get fieldUnit(): UnitReference | undefined {
      return this.field?.unit;
   }

   set fieldUnit(value: UnitReference | undefined) {
      let reference = value ? new UnitReference(value) : undefined;
      this.$set(this.field, "unit", reference);
   }

   get fieldUnitType(): ItemReference | undefined {
      return this.field?.unitType;
   }

   set fieldUnitType(value: ItemReference | undefined) {
      // if unit/unitType mismatch, reset unit
      if (this.fieldUnit && this.fieldUnit.unitType!.id !== value.id) {
         this.fieldUnit = undefined;
      }
      let reference = value ? new ItemReference(value) : undefined;
      this.$set(this.field, "unitType", reference);
   }

   get fieldSubContentBrick(): ItemReference | undefined {
      return this.field?.subContentBrick;
   }

   set fieldSubContentBrick(value: ItemReference | undefined) {
      let reference = value ? new ItemReference(value) : undefined;
      this.$set(this.field, "subContentBrick", reference);
      this.$set(this.field, "subContentBrickCode", reference?.code);
   }

   fieldListReference: ItemReference | undefined = undefined;

   get fieldList(): ItemReference | undefined {
      var listId = this.field?.listId;
      if (!listId) {
         return undefined;
      }

      var listReference = this.fieldListReference;

      return listReference ? new ItemReference(listReference) : undefined;
   }

   set fieldList(value: ItemReference | undefined) {
      this.fieldListReference = value;
      this.$set(this.field, "listId", value?.id);
   }

   fieldNameLabel(type: ContentBrickFieldType): string {
      return type === ContentBrickFieldType.Document ? "Document name" : "Name";
   }

   onContentBrickFieldNameBlur() {
      if (!this.fieldName) return;

      if (this.fieldName.length > 0 && this.fieldIdentifier === undefined) {
         // when field name has value, and identifier was never set
         this.regenerateIdentifier();
      }
   }

   regenerateIdentifier() {
      if (!this.fieldName) return;

      this.fieldIdentifier = DataModelUtils.generateNodeIdentifier(this.fieldName); // prefill identifier from name
   }

   @Watch("field.type")
   onFieldTypeChanged() {
      if (this.field.type === ContentBrickFieldType.Calculated && !this.field.fieldCalculation) {
         this.$set(
            this.field,
            "fieldCalculation",
            new FieldCalculationDefinition({
               queries: [],
               formula: "",
            })
         );
      }

      if (this.field.type === ContentBrickFieldType.ComboBox && !this.field.fieldCalculation) {
         this.$set(
            this.field,
            "list",
            List.fromJS({
               items: [],
            })
         );
      }

      if (AttachmentUtils.isImageField(this.field.type) && this.imageTypeComboItems.length == 1) {
         this.field.imageType = this.imageTypeComboItems[0].value;
      } else if (AttachmentUtils.isVideoField(this.field.type) && this.videoTypeComboItems.length === 1) {
         this.field.videoType = this.videoTypeComboItems[0].value;
      }

      if (!this.isRegularExpressionEnabled()) {
         this.selectedRegulaExpression = undefined;
      }
   }

   addContentBrickField(): void {
      if (this.$refs.detailForm.validate()) {
         this.$emit("addContentBrickField", this.field);
      }
   }

   reloadDependencies() {
      this.$emit("reloadDependencies");
   }

   hideDetailDialog(): void {
      this.$emit("hideDetailDialog");
   }

   get newItemRoute(): string | null {
      return this.isListFieldTypeSelected ? "/list/new" : this.isCalculatedFieldTypeSelected ? "/units/new" : null;
   }

   get clickReloadFunc(): (() => void) | null {
      return this.isUpdatableFieldTypeSelected ? this.reloadDependencies : null;
   }

   clickClose() {
      this.hideDetailDialog();
   }

   mounted() {
      this.loadRouteTranslations(this.routeNames);
      EventBus.$on(Events.LanguageChanged, () => {
         this.loadRouteTranslations(this.routeNames);
      });
   }

   // --------- Regular Expressions ---------
   selectedRegulaExpression: ItemReference | undefined = undefined;

   get fieldRegularExpression(): ItemReference | undefined {
      if (this.field?.regularExpressionId) {
         if (this.selectedRegulaExpression && this.selectedRegulaExpression.id == this.field.regularExpressionId) {
            return this.selectedRegulaExpression;
         } else {
            void this.loadAndSetMissingSelectedRegularExpression(this.field.regularExpressionId);

            if (!this.selectedRegulaExpression) {
               this.notifyError(`Load Regular Expression failed. Regular expression not found.`);
               return undefined;
            }

            return this.selectedRegulaExpression;
         }
      } else {
         return undefined;
      }
   }

   async loadAndSetMissingSelectedRegularExpression(regularExpressionId: string) {
      const result = (await this.loadRegularExpressionsByIds([regularExpressionId])) ?? [];
      this.selectedRegulaExpression = result.find((r) => r.id === regularExpressionId);
   }

   set fieldRegularExpression(value: ItemReference | undefined) {
      if (value) {
         this.selectedRegulaExpression = new ItemReference(value);
         this.$set(this.field, "regularExpressionId", this.selectedRegulaExpression.id);
      } else {
         this.selectedRegulaExpression = undefined;
         this.$set(this.field, "regularExpressionId", undefined);
      }
   }

   isRegularExpressionEnabled(): boolean {
      return RegularExpressionUtils.isRegularExpressionEnabled(this.field?.type) && !this.isDataTableColumnMode;
   }

   //--------------- Data Tables -------------------
   selectedDataTable: ItemReference | undefined = undefined;

   get dataTable(): ItemReference | undefined {
      if (this.field?.dataTableId) {
         if (this.selectedDataTable && this.selectedDataTable.id === this.field.dataTableId) {
            return this.selectedDataTable;
         } else {
            throw `Load Data Table failed. Data Table with ID: '${this.field?.dataTableId}' not found.`;
         }
      } else {
         return undefined;
      }
   }

   set dataTable(value: ItemReference | undefined) {
      if (value) {
         this.selectedDataTable = new ItemReference(value);
         this.field.dataTable = value;
         this.$set(this.field, "dataTableId", this.selectedDataTable.id);
      } else {
         this.selectedDataTable = undefined;
         this.dataTable = undefined;
         this.$set(this.field, "dataTableId", undefined);
      }
   }

   selectedDataTableColumns: DataTableColumnReference[] | undefined = undefined;

   get dataTableColumns(): DataTableColumnReference[] | undefined {
      if (this.field.visibleColumns) {
         return this.field.visibleColumns;
      } else {
         return undefined;
      }
   }

   set dataTableColumns(value: DataTableColumnReference[] | undefined) {
      if (value) {
         this.selectedDataTableColumns = [...value];
         this.$set(this.field, "visibleColumns", [...value]);
      } else {
         this.selectedDataTableColumns = undefined;
         this.$set(this.field, "visibleColumns", undefined);
      }
   }

   get isDataTableVisible(): boolean {
      return this.field?.type === ContentBrickFieldType.DataTable;
   }

   //---------- Calculated fields ----------
   get calculatableFields(): IFieldCalculationReference[] {
      return ContentBrickUtils.getCalculatableContentBrickFields(this.field, this.fields);
   }

   // -------- Validation -------------
   validatingMin: boolean = false;
   validatingMax: boolean = false;

   rules = {
      field: this.field,
      name: [
         (v) => !!v?.trim() || "Name is required",
         (v) => v?.length <= 80 || "Name must be less than 80 characters",
      ],
      identifier: [
         ...ValidationRules.identifier,
         (v) =>
            this.fields
               .concat(this.metadataFields)
               .map((field) => field.identifier)
               .filter((identifier) => !!identifier)
               .findIndex((identifier) => identifier === v) < 0 || "Identifier is already used for another field",
      ],
      type: [(v) => v !== undefined || "Data type is required"],
      unit: [(v) => !!v || "Unit is required"],
      unitType: [(v) => !!v || "Unit Type is required"],
      documentType: [
         (v) => {
            return !!v || v > -1 || "Document type is required";
         },
      ],
      imageType: [
         (v) => {
            return !!v || v > -1 || "Image type is required";
         },
      ],
      videoType: [
         (v) => {
            return !!v || v > -1 || "Video type is required";
         },
      ],
      listType: [
         (v) => {
            return !!v || "List type is required";
         },
      ],
      documentMinCount: [
         (v) => ![null, undefined, ""].includes(v) || "Minimum count is required",
         (v) => v >= 0 || "Minimum count must be >= 0",
         (v) => {
            if (this.$refs.txtMaxCount === undefined) return true;
            if (!this.validatingMax) {
               this.validatingMin = true;
               this.$refs.txtMaxCount.validate();
               this.validatingMin = false;
            }
            let min: number = parseInt(v);
            let max: number = parseInt(this.$refs.txtMaxCount.value);
            return min <= max || "Min should be less or equal max";
         },
      ],
      documentMaxCount: [
         (v) => ![null, undefined, ""].includes(v) || "Maximum count is required",
         (v) => v >= 1 || "Maximum count must be >= 1",
         (v) => {
            if (this.$refs.txtMinCount === undefined) return true;
            if (!this.validatingMin) {
               this.validatingMax = true;
               this.$refs.txtMinCount.validate();
               this.validatingMax = false;
            }
            let min: number = parseInt(this.$refs.txtMinCount.value);
            let max: number = parseInt(v);
            return min <= max || "Max should be greater or equal min";
         },
      ],
      imageMinCount: [
         (v) => ![null, undefined, ""].includes(v) || "Minimum count is required",
         (v) => v >= 0 || "Minimum count must be >= 0",
         (v) => {
            if (this.$refs.txtImageMaxCount === undefined) return true;
            if (!this.validatingMax) {
               this.validatingMin = true;
               this.$refs.txtImageMaxCount.validate();
               this.validatingMin = false;
            }
            let min: number = parseInt(v);
            let max: number = parseInt(this.$refs.txtImageMaxCount.value);
            return min <= max || "Min should be less or equal max";
         },
      ],
      imageMaxCount: [
         (v) => ![null, undefined, ""].includes(v) || "Maximum count is required",
         (v) => v >= 1 || "Maximum count must be >= 1",
         (v) => {
            if (this.$refs.txtImageMinCount === undefined) return true;
            if (!this.validatingMin) {
               this.validatingMax = true;
               this.$refs.txtImageMinCount.validate();
               this.validatingMax = false;
            }
            let min: number = parseInt(this.$refs.txtImageMinCount.value);
            let max: number = parseInt(v);
            return min <= max || "Max should be greater or equal min";
         },
      ],
      calculationForumula: [(v) => !!v || "Formula is required"],
      subContentBrick: [
         (v) => {
            return !!v || "Sub Content Brick is required";
         },
      ],
      booleanLayout: [
         (v) => {
            return v != null || "Layout is required";
         },
      ],
   };
}
</script>
